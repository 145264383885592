import React, { useMemo, useEffect, useState } from "react";
import {
	useFilters,
	useGlobalFilter,
	useTable,
	useAsyncDebounce,
} from "react-table";
import Button from "../components/elements/Button";
import { Link, Redirect } from "react-router-dom";
import { useAppContext } from "../context/appContext";

const initialValues = { memberData: [], schoolData: [] };

function MemberListView() {
	const [values, setValues] = useState(initialValues);
	const { members, isLoading, errorMessage, getAllMembers, user, schools } = useAppContext();
	useEffect(() => {
		(async () => {
			getAllMembers();
		})();
	}, []);

	return (
		<React.Fragment>
			{!user && <Redirect to="/login" />}
			<div className="container">
				<div className=" sm:mb-0 mt-6">
					<div className="row">
						<h3
							className="text-md md:text-xl text-slate-800 font-bold column"
							style={{ paddingTop: 50 }}
						>
							Members ✨
						</h3>
					</div>
				</div>
				{isLoading || !members ? (
					<div className="hero-inner section-inner center-content">
						<Button loading={isLoading}>Loading</Button>
					</div>
				) : members && members.length !== 0 ? (
					<div className="hero-inner section-inner center-content">
						<MemberTable data={members.map((memberDoc) => { const schoolData = schools.map((schoolDoc) => schoolDoc.data()); return ({ ...memberDoc.data(), firstLast: memberDoc.data()["firstName"] + " " + memberDoc.data()["lastName"], schoolHouse: memberDoc.data()["school"] + " " + memberDoc.data()["greekHouse"]["realName"], schoolShortHouse: memberDoc.data()["school"] && memberDoc.data()["school"] !== "NO_SCHOOL" ? schoolData.filter((schoolDoc) => schoolDoc.realName === memberDoc.data().school)[0].shortName + " " + memberDoc.data()["greekHouse"]["realName"] : "" }) })} />
					</div>
				) : (
					<div
						className="container has-top-divider center-content"
						style={{ paddingBottom: 10 }}
					>
						<h4 style={{ paddingTop: 100 }}>No Members yet!</h4>
						<div className="container section-inner">


						</div>
					</div>
				)}
			</div>
		</React.Fragment>
	);
}

export default MemberListView;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
	const [value, setValue] = useState(globalFilter);
	const onChange = useAsyncDebounce((value) => {
		setGlobalFilter(value || undefined);
	}, 200);
	return (
		<input
			className="divide-y"
			style={{
				width: "25%",
				borderColor: "gray",
				borderWidth: 1,
				borderRadius: 10,
				padding: 5,
				marginBottom: 20,
			}}
			value={value}
			onChange={(e) => {
				setValue(e.target.value);
				onChange(e.target.value);
			}}
			placeholder="Filter..."
		></input>
	);
}

function MemberTable({ data }) {
	const columns = useMemo(() => [
		{
			Header: "School",
			accessor: "school",
		},
		{
			Header: "House",
			accessor: "greekHouse.realName",
		},
		{
			Header: "First Name",
			accessor: "firstName",
		},
		{
			Header: "Last Name",
			accessor: "lastName",
		},
		{
			Header: "Email Address",
			accessor: "emailAddress",
		},
		{
			h: true,
			Header: "",
			accessor: "firstLast"
		},
		{
			h: true,
			Header: "",
			accessor: "schoolHouse"
		},
		{
			h: true,
			Header: "",
			accessor: "schoolShortHouse"
		}

	]);

	const { user } = useAppContext();

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		state,
		visibleColumns,
		prepareRow,
		setGlobalFilter,
		preGlobalFilteredRows,
	} = useTable(
		{
			columns,
			data,
			autoResetHiddenColumns: false,
		},
		useFilters,
		useGlobalFilter
	);

	return (
		<>
			<table
				className="table-auto w-full divide-y divide-slate-200"
				{...getTableProps()}
			>
				<thead>
					<tr>

						<th
							colSpan={visibleColumns.length}
							className="center-content container"
						>
							<GlobalFilter
								preGlobalFilteredRows={preGlobalFilteredRows}
								globalFilter={state.globalFilter}
								setGlobalFilter={setGlobalFilter}
							/>

						</th>

					</tr>

					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps()}
									style={{
										textAlign: "center",
									}}
								>
									{column.render("Header")}
								</th>
							))}

						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<tr key={i}>
								<td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
									<div className="font-bold text-slate-800">
										<div style={{ textAlign: "center" }}>
											{row.cells[0].value}
										</div>
									</div>
								</td>
								<td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
									<div style={{ textAlign: "center" }}>
										{row.cells[1].value}
									</div>
								</td>
								<td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
									<div style={{ textAlign: "center" }}>
										{row.cells[2].value}
									</div>
								</td>
								<td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
									<div style={{ textAlign: "center" }}>
										{row.cells[3].value}
									</div>
								</td>
								<td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
									<div style={{ textAlign: "center" }}>
										{row.cells[4].value}
									</div>
								</td>
								
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
}
