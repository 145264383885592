import React from 'react';
import HeroFull from '../components/sections/HeroFull';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import {Redirect} from "react-router-dom"
import { withUser } from "../components/layout/withUser";

class Home extends React.Component {
  render() {
    const user = this.props.user
    return (
      <React.Fragment>
        {user && <Redirect to="/events"/>}
        <HeroFull className="illustration-section-01" />
        <FeaturesTiles className="illustration-section-02" />

      </React.Fragment>
    );
  }
}

export default withUser(Home);
