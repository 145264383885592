import React, { useMemo, useEffect, useState } from "react";
import {
    useFilters,
    useGlobalFilter,
    useTable,
    useAsyncDebounce,
} from "react-table";
import Button from "../../components/elements/Button";
import { Link, Redirect } from "react-router-dom";
import { useAppContext } from "../../context/appContext";

const initialValues = {};

function AdminEventListView() {
    const [values, setValues] = useState(initialValues);
    const { events, isLoading, errorMessage, user, admin_getAllEvents } = useAppContext();
    useEffect(() => {
        (async () => {
            admin_getAllEvents();
        })();
    }, []);

    return (
        <React.Fragment>
            {!user && !user.admin && <Redirect to="/login" />}
            <div className="container">
                <div className=" sm:mb-0 mt-6">
                    <div className="row">
                        <h3
                            className="text-md md:text-xl text-slate-800 font-bold column"
                            style={{ paddingTop: 50 }}
                        >
                            Admin View - Events ✨
                        </h3>

                    </div>
                    
                    <Link to="/admin/user/create">
                        <Button>Add Authorized User</Button>
                    </Link>
                </div>
                {isLoading || !events ? (
                    <div className="hero-inner section-inner center-content">
                        <Button loading={isLoading}>Loading</Button>
                    </div>
                ) : events && events.length !== 0 ? (
                    <div className="hero-inner section-inner center-content">
                        <EventTable data={events} />
                    </div>
                ) : (
                    <div
                        className="container has-top-divider center-content"
                        style={{ paddingBottom: 10 }}
                    >
                        <h4 style={{ paddingTop: 100 }}>No Events yet!</h4>
                        <div className="container section-inner">
                            {user && user.isAuthorized &&
                                <Link to="/events/create">
                                    <Button>Add Event</Button>
                                </Link>
                            }

                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default AdminEventListView;

function GlobalFilter({ globalFilter, setGlobalFilter }) {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);
    return (
        <input
            className="divide-y"
            style={{
                width: "25%",
                borderColor: "gray",
                borderWidth: 1,
                borderRadius: 10,
                padding: 5,
                marginBottom: 20,
            }}
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            placeholder="Search..."
        ></input>
    );
}

function EventTable({ data }) {
    const columns = useMemo(() => [
        {
            Header: "School",
            accessor: "school",
        },
        {
            Header: "House Holding",
            accessor: "houseHolding",
        },
        {
            Header: "Event Name",
            accessor: "eventName",
        },
        {
            Header: "Date",
            accessor: "date",
        },
        {
            Header: "Start Time",
            accessor: "startTime",
        },
        {
            Header: "End Time",
            accessor: "endTime",
        },
    ]);

    const { user } = useAppContext();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state,
        visibleColumns,
        prepareRow,
        setGlobalFilter,
        preGlobalFilteredRows,
    } = useTable(
        {
            columns,
            data,
            autoResetHiddenColumns: false,
        },
        useFilters,
        useGlobalFilter
    );

    return (
        <>
            <table
                className="table-auto w-full divide-y divide-slate-200"
                {...getTableProps()}
            >
                <thead>
                    <tr>
                        <th
                            colSpan={visibleColumns.length}
                            className="center-content container"
                        >
                            <GlobalFilter
                                preGlobalFilteredRows={preGlobalFilteredRows}
                                globalFilter={state.globalFilter}
                                setGlobalFilter={setGlobalFilter}
                            />
                            {user && user.isAuthorized &&
                                <Link to="/events/create" style={{ paddingLeft: 10 }}>
                                    <Button size="sm">Add Event</Button>
                                </Link>
                            }
                        </th>
                    </tr>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                            {user && user.isAuthorized && (
                                <th
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    Options
                                </th>
                            )}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr key={i}>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-bold text-slate-800">
                                        <div style={{ textAlign: "center" }}>
                                            {row.cells[0].value}
                                        </div>
                                    </div>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div style={{ textAlign: "center" }}>
                                        {row.cells[1].value}
                                    </div>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div style={{ textAlign: "center" }}>
                                        {row.cells[2].value}
                                    </div>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div style={{ textAlign: "center" }}>
                                        {row.cells[3].value}
                                    </div>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div style={{ textAlign: "center" }}>
                                        {row.cells[4].value}
                                    </div>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div style={{ textAlign: "center" }}>
                                        {row.cells[5].value}
                                    </div>
                                </td>
                                {user && user.isAuthorized && (
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">

                                        <a
                                            href={`/events/detail/${data[i].id}`}
                                        >
                                            <Button className="mt-15" size="sm" wide>
                                                Edit
                                            </Button>
                                        </a>

                                        <a
                                            href={`/events/live/${data[i].id}`}

                                        >
                                            <Button className="mt-15" size="sm" wide>
                                                Live View
                                            </Button>
                                        </a>
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}
