import React, { useEffect, useState } from "react";
import Input from "../../components/elements/Input";
import Button from "../../components/elements/Button";
import Switch from "../../components/elements/Switch";
import Select from "react-select";
import { useAppContext } from "../../context/appContext";
import { Redirect, useHistory } from "react-router-dom"

const initState = {firstName: "", lastName: "", email: "", password: "", }

export default function AdminCreateView () {
    const [values, setValues] = useState(initState)
    const {isLoading, admin_createAdmin, errorMessage, successMessage, handleChange, user} = useAppContext();

    const handleInputChange = (e) => {
        e.preventDefault()
        setValues({...values, [e.target.name]: e.target.value})
    }

    const onSubmit = (e) => {
        e.preventDefault();
        const {firstName, lastName, email, password} = values
        if(!firstName || !lastName || !email || !password) {
            handleChange("errorMessage", "Please input all values!")
            return
        } else {
            admin_createAdmin({firstName, lastName, email, password})
            
        }
    }
    return (
        <React.Fragment>
			<div className="container">
				<div className="mb-4 sm:mb-0 mt-6">
					<h3
						className="text-md md:text-xl text-slate-800 font-bold"
						style={{ paddingTop: 50 }}
					>
						Create an Admin User
					</h3>
					
					</div>
				{isLoading || !user ? (
					<div className="hero-inner section-inner center-content">
						<Button loading={isLoading}>Loading</Button>
					</div>
				) : (
					<div
						className="container has-top-divider"
						style={{ paddingBottom: 0 }}
					>
						<div className="container section-inner">
							<Input
								type="text"
								name="firstName"
								value={values.firstName}
								onChange={handleInputChange}
								label="First Name"
							/>
							<Input
								type="text"
								name="lastName"
								value={values.lastName}
								onChange={handleInputChange}
								label="Last Name"
							/>
							<Input
								type="email"
								name="email"
								value={values.email}
								onChange={handleInputChange}
								label="Email"
							/>
							<Input
								type="password"
								name="password"
								value={values.password}
								onChange={handleInputChange}
								label="Temporary Password"
							/>
							
							<div />
							
						</div>
						
						<div className="center-content" style={{ padding: 15 }}>
							<Button className="mt-15" onClick={onSubmit}>
								Submit
							</Button>
						</div>
						{errorMessage.length > 0 ? (
							<div
								className="mt-24"
								style={{ background: "white", borderRadius: 15 }}
							>
								<p
									style={
										errorMessage.length !== 0
											? {
												padding: 5,
												color: "red",
												fontSize: 15,
												textAlign: "center",
											}
											: {}
									}
								>
									{errorMessage}
								</p>
							</div>
						) : (
							<div />
						)}
						{successMessage.length > 0 ? (
							<div
								className="mt-24"
								style={{ background: "white", borderRadius: 15 }}
							>
								<p
									style={
										successMessage.length !== 0
											? {
												padding: 5,
												color: "green",
												fontSize: 15,
												textAlign: "center",
											}
											: {}
									}
								>
									{successMessage}
								</p>
							</div>
						) : (
							<div />
						)}
					</div>
				)}
			</div>
		</React.Fragment>
    )
}