import React, { useMemo, useEffect, useState } from "react";
import {
    useFilters,
    useGlobalFilter,
    useTable,
    useAsyncDebounce,
} from "react-table";
import Button from "../../components/elements/Button";
import { Link, Redirect, useParams } from "react-router-dom";
import { useAppContext } from "../../context/appContext";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../utils/firebase";
import * as Papa from "papaparse";
import Modal from "../../components/elements/Modal";
import FormLabel from "../../components/elements/FormLabel";
import Input from "../../components/elements/Input";

const initialValues = { email: "", drinks: [], errorMessage: "", view: "acceptedInvitees", firstName: "", lastName: "", house: "", school: "", };

function EventLiveView() {
    const { id } = useParams();
    const [values, setValues] = useState(initialValues);
    const [testData, setTestData] = useState([]);
    const [checkInModalOpen, setCheckInModalOpen] = useState(false)
    const [closeEventModalOpen, setCloseEventModalOpen] = useState(false)
    const { obj, isLoading, errorMessage, getLiveEvent, user, checkInAtEventForm, lockEvent, markCSVExportTime } = useAppContext();

    useEffect(() => {
        const eventsDB = collection(db, "Events");
        const eventDoc = doc(eventsDB, id);

        //real time update
        onSnapshot(eventDoc, (snapshot) => {
            setTestData(snapshot.data())
        })
    }, [])

    const handleCheckInChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const handleDrinkChange = (e, index) => {
        let newDrinks = values.drinks
        newDrinks[index][e.target.name] = e.target.value
        setValues({ ...values, drinks: newDrinks })
    }

    const delDrinkRow = (e, index) => {
        var newDrinks = values.drinks;
        newDrinks.splice(index, 1);
        setValues({ ...values, drinks: newDrinks })
    };

    const validatedDrinks = (drinks) => {
        for (let i = 0; i < drinks.length; i++) {
            if (drinks[i].drinkName === "" || drinks[i].drinkQuantity === 0) {
                return false
            }
        }
        return true
    }

    const onCheckInSubmit = (e) => {
        e.preventDefault();
        setValues({ ...values, errorMessage: "" })
        var drinksObject = {}
        if (values.email.length === 0) {
            setValues({ ...values, errorMessage: "Please Input an Email Address" })
            return
        }
        if (!validatedDrinks(values.drinks)) {
            setValues({ ...values, errorMessage: "Please make sure all drink information has been entered (No drink name or quantity is 0)" })
            return
        }
        if (values.drinks.length === 0) {
            drinksObject = { "No Drinks": 0 }
        } else {
            for (let i = 0; i < values.drinks.length; i++) {
                drinksObject[values.drinks[i].drinkName] = values.drinks[i].drinkQuantity
            }
        }
        if (!values.firstName || !values.house || !values.lastName || !values.school) {
            setValues({ ...values, errorMessage: "Please input all user information" })
            return
        }
        const userData = { firstName: values.firstName, house: values.house, lastName: values.lastName, school: values.school }
        if (testData.isBYOB) { checkInAtEventForm(id, values.email, drinksObject, userData) } else { checkInAtEventForm(id, values.email, {}, userData) }

    }

    const onDownloadClick = (e) => {
        e.preventDefault();
        const columnsBYOB = ["scannedEventID", "scannedInviteType", "email", "scannedFirstName", "scannedLastName", "scannedHouse", "scannedSchool", "scannedTime", "drinks", "scannerFirstName", "scannerLastName", "scannerEmail", "scannerHouse", "checkoutScannerFirstName", "checkoutScannerLastName", "checkoutScannerEmail", "checkoutScannerHouse", "checkoutScanTime"]
        const columns = ["scannedEventID", "scannedInviteType", "email", "scannedFirstName", "scannedLastName", "scannedHouse", "scannedSchool", "scannedTime", "scannerFirstName", "scannerLastName", "scannerEmail", "scannerHouse", "checkoutScannerFirstName", "checkoutScannerLastName", "checkoutScannerEmail", "checkoutScannerHouse", "checkoutScanTime"]
        var csv = Papa.unparse(Object.keys(testData.checkedInUsers).map(accepted => { return { email: accepted, drinks: testData.isBYOB ? Object.keys(testData.checkedInUsers[accepted].byobDrinks).filter(newKey => newKey !== "No Drinks").map(drink => drink + ': ' + testData.checkedInUsers[accepted].byobDrinks[drink]).join(", ") : "", ...testData.checkedInUsers[accepted], checkoutScannerFirstName: testData.checkedOutUsers[accepted] ? testData.checkedOutUsers[accepted].scannerFirstName : "", checkoutScannerLastName: testData.checkedOutUsers[accepted] ? testData.checkedOutUsers[accepted].scannerLastName : "", checkoutScannerEmail: testData.checkedOutUsers[accepted] ? testData.checkedOutUsers[accepted].scannerEmail : "", checkoutScannerHouse: testData.checkedOutUsers[accepted] ? testData.checkedOutUsers[accepted].scannerHouse : "", checkoutScanTime: testData.checkedOutUsers[accepted] ? testData.checkedOutUsers[accepted].scannedTime : "" } }), { columns: testData.isBYOB ? columnsBYOB : columns })
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, testData.eventName + " " + testData.date + ' Full.csv');
        } else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', testData.eventName + " " + testData.date + ' Full.csv');
        tempLink.click();
        markCSVExportTime({eventId: id, csvType: "Full"})
    }



    return (
        <React.Fragment>
            <div className="container">
                <Modal show={closeEventModalOpen} handleClose={(e) => { e.preventDefault(); setCloseEventModalOpen(false) }}  >
                    <h4>Confirm Close</h4>
                    <p>Would you like to close this event? This means that no more users can be checked in or checked out!</p>
                    <Button style={{margin: 10}} onClick={(e) => { e.preventDefault(); lockEvent({ eventId: id }); setCloseEventModalOpen(true)}}>Confirm</Button>
                </Modal>
                <Modal show={checkInModalOpen} handleClose={(e) => { e.preventDefault(); setCheckInModalOpen(false) }}>
                    <h4>Check In Form</h4>
                    <FormLabel id="email">Email</FormLabel>
                    <Input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        onChange={(e) => {
                            handleCheckInChange(e);
                        }}
                    />
                    <FormLabel id="firstName">First Name</FormLabel>
                    <Input
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={values.firstName}
                        onChange={(e) => {
                            handleCheckInChange(e);
                        }}
                    />
                    <FormLabel id="lastName">Last Name</FormLabel>
                    <Input
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={values.lastName}
                        onChange={(e) => {
                            handleCheckInChange(e);
                        }}
                    />
                    <FormLabel id="House">House</FormLabel>
                    <Input
                        type="text"
                        name="house"
                        placeholder="House"
                        value={values.house}
                        onChange={(e) => {
                            handleCheckInChange(e);
                        }}
                    />
                    <FormLabel id="school">School</FormLabel>
                    <Input
                        type="text"
                        name="school"
                        placeholder="School"
                        value={values.school}
                        onChange={(e) => {
                            handleCheckInChange(e);
                        }}
                    />
                    {testData.isBYOB &&
                        values.drinks.map((drink, index) => <div key={index}>
                            <FormLabel id="drink">Drink {index + 1} </FormLabel>
                            <Input

                                type="text"
                                name="drinkName"
                                placeholder="Drink Name"
                                value={drink.drinkName}
                                onChange={(e) => {
                                    handleDrinkChange(e, index);
                                }}
                            />
                            <Input
                                type="number"
                                name="drinkQuantity"
                                placeholder="Drink Quantity"
                                value={drink.drinkQuantity}
                                onChange={(e) => {
                                    handleDrinkChange(e, index);
                                }}
                            />
                            <Button size="sm" onClick={(e) => delDrinkRow(e, index)}>Delete</Button>
                        </div>)
                    }
                    <h1></h1>
                    {testData.isBYOB && <Button size="sm" color="secondary" onClick={(e) => { e.preventDefault(); let newDrinks = [...values.drinks, { drinkName: "", drinkQuantity: 0 }]; setValues({ ...values, drinks: newDrinks }) }}>Add Drink</Button>
                    }<h1></h1>
                    <Button size="sm" onClick={onCheckInSubmit}>Submit</Button>
                    <div
                        className="mt-24 mb-32"
                        style={{ background: "white", borderRadius: 15 }}
                    >
                        <p
                            style={
                                values.errorMessage.length !== 0
                                    ? {
                                        padding: 5,
                                        color: "red",
                                        fontSize: 15,
                                        textAlign: "center",
                                    }
                                    : {}
                            }
                        >
                            {values.errorMessage}
                        </p>
                    </div>
                    <div
                        className="mt-24 mb-32"
                        style={{ background: "white", borderRadius: 15 }}
                    >
                        <p
                            style={
                                errorMessage.length !== 0
                                    ? {
                                        padding: 5,
                                        color: "red",
                                        fontSize: 15,
                                        textAlign: "center",
                                    }
                                    : {}
                            }
                        >
                            {errorMessage}
                        </p>
                    </div>
                </Modal>
                <div className=" sm:mb-0 mt-6">
                    <div className="row">
                        <h3
                            className="text-md md:text-xl text-slate-800 font-bold column"
                            style={{ paddingTop: 50 }}
                        >
                            Event Live View {testData.lockEvent ? <span style={{color: "red"}}>Closed</span> : ""}
                        </h3>

                    </div>
                </div>
                {isLoading || !testData ? (
                    <div className="hero-inner section-inner center-content">
                        <Button loading={isLoading}>Loading</Button>
                    </div>
                ) : ((
                    <div className="section-inner">
                        <div className="tiles-wrap">
                            {testData.eventName &&
                                <div className="tiles-item" >
                                    <div className="tiles-item-inner">
                                        <div className="pricing-item-content">
                                            <div className="pricing-item-header pb-24 mb-24">
                                                <div className="pricing-item-price mb-4">
                                                    <span className="pricing-item-price-amount h4">
                                                        {testData.eventName}
                                                    </span>
                                                </div>
                                                <div className="text-s">
                                                    Event Name
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            }

                            {testData.eventDescription &&
                                <div className="tiles-item " >
                                    <div className="tiles-item-inner">
                                        <div className="pricing-item-content">
                                            <div className="pricing-item-header pb-24 mb-24">
                                                <div className="pricing-item-price mb-4">
                                                    <span className="pricing-item-price-amount h4">
                                                        {testData.eventDescription}
                                                    </span>
                                                </div>
                                                <div className="text-s">
                                                    Event Description
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            }

                            {testData.date && testData.startTime &&
                                <div className="tiles-item " >
                                    <div className="tiles-item-inner">
                                        <div className="pricing-item-content">
                                            <div className="pricing-item-header pb-24 mb-24">
                                                <div className="pricing-item-price mb-4">
                                                    <span className="pricing-item-price-amount h4">
                                                        {testData.date}
                                                    </span>
                                                </div>
                                                <div className="pricing-item-price mb-4">
                                                    <span className="pricing-item-price-amount h4">
                                                        {testData.startTime}-{testData.endTime}
                                                    </span>
                                                </div>
                                                {testData.eventLocation && <div className="pricing-item-price mb-4">
                                                    <span className="pricing-item-price-amount h4">
                                                        {testData.eventLocation}
                                                    </span>
                                                </div>}
                                                <div className="text-s">
                                                    Event Date, Time, Location
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            }

                            {testData.acceptedInvitees ?
                                <div className="tiles-item " >
                                    <div className="tiles-item-inner">
                                        <div className="pricing-item-content">
                                            <div className="pricing-item-header pb-24 mb-24">
                                                <div className="pricing-item-price mb-4">
                                                    <span className="pricing-item-price-amount h1">
                                                        {Object.keys(testData.acceptedInvitees).length}
                                                    </span>
                                                </div>
                                                <div className="text-s">
                                                    Total Accepted Invitees
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                                :
                                <div className="tiles-item " >
                                    <div className="tiles-item-inner">
                                        <div className="pricing-item-content">
                                            <div className="pricing-item-header pb-24 mb-24">
                                                <div className="pricing-item-price mb-4">
                                                    <span className="pricing-item-price-amount h1">
                                                        0
                                                    </span>
                                                </div>
                                                <div className="text-s">
                                                    Total Accepted Invitees
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            }

                            {testData.checkedInUsers ?
                                <div className="tiles-item " >
                                    <div className="tiles-item-inner">
                                        <div className="pricing-item-content">
                                            <div className="pricing-item-header pb-24 mb-24">
                                                <div className="pricing-item-price mb-4">
                                                    <span className="pricing-item-price-amount h1">
                                                        {Object.keys(testData.checkedInUsers).length}
                                                    </span>
                                                </div>
                                                <div className="text-s">
                                                    Checked In
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div> :
                                <div className="tiles-item " >
                                    <div className="tiles-item-inner">
                                        <div className="pricing-item-content">
                                            <div className="pricing-item-header pb-24 mb-24">
                                                <div className="pricing-item-price mb-4">
                                                    <span className="pricing-item-price-amount h1">
                                                        0
                                                    </span>
                                                </div>
                                                <div className="text-s">
                                                    Checked In
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            }

                            {testData.housesList &&
                                <div className="tiles-item " >
                                    <div className="tiles-item-inner">
                                        <div className="pricing-item-content">
                                            <div className="pricing-item-header pb-24 mb-24">

                                                {testData.housesList.map((house) => {
                                                    return <p key={house} className="pricing-item-price-amount text-md h4">

                                                        {house}

                                                    </p>
                                                })}
                                                <div className="text-s">
                                                    Houses Invited
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            }





                        </div>
                        <div className="row" style={{ justifyContent: "space-between", borderBottom: 1 }}>
                            <Button color="primary" style={{ marginBottom: 15 }} onClick={(e) => { e.preventDefault(); setValues({ ...values, view: "acceptedInvitees" }) }}>View Accepted Invitees</Button>
                            <Button color="primary" style={{ marginBottom: 15 }} onClick={(e) => { e.preventDefault(); setValues({ ...values, view: "checkedInUsers" }) }}>View Checked In Users</Button>
                            <Button color="primary" style={{ marginBottom: 15 }} onClick={(e) => { e.preventDefault(); setValues({ ...values, view: "checkedOutUsers" }) }}>View Checked Out Users</Button>
                            <Button color="primary" style={{ marginBottom: 15 }} onClick={(e) => { e.preventDefault(); setValues({ ...values, view: "eventStaff" }) }}>View Event Staff</Button>
                            <Button color="secondary" disabled={testData.lockEvent} style={{ marginBottom: 15 }} onClick={(e) => { e.preventDefault(); setCheckInModalOpen(true) }}>Check In User</Button>
                            <Button color="secondary" disabled={testData.lockEvent} style={{ marginBottom: 15 }} onClick={(e) => { e.preventDefault(); setCloseEventModalOpen(true) }}>Close Event</Button>
                        <Button color="secondary" style={{ marginBottom: 15 }} onClick={onDownloadClick}>Download Full CSV</Button>
                    </div>
                        {values.view === "acceptedInvitees" && (testData.acceptedInvitees && Object.keys(testData.acceptedInvitees).length > 0 ? <div className="container center-content">

                    {testData.checkedInUsers ? (Object.keys(testData.acceptedInvitees).filter(accepted => !testData.checkedInUsers[accepted]).length > 0 ? (testData.isBYOB ?
                        <EventTableAcceptedInvitees lockEvent={testData.lockEvent} eventID={id} testData={testData} isBYOB={testData.isBYOB} eventName={testData.eventName + " " + testData.date} data={Object.keys(testData.acceptedInvitees).map((key) => ({ ...testData.acceptedInvitees[key], email: key, drinks: Object.keys(testData.acceptedInvitees[key]).filter(newKey => newKey !== "No Drinks").map(drink => drink + ': ' + testData.acceptedInvitees[key][drink]).join(", ") })).filter(accepted => !testData.checkedInUsers[accepted.email])} />
                        :
                        <EventTableAcceptedInvitees lockEvent={testData.lockEvent} eventID={id} testData={testData} isBYOB={testData.isBYOB} eventName={testData.eventName + " " + testData.date} data={Object.keys(testData.acceptedInvitees).map((key) => ({ ...testData.acceptedInvitees[key], email: key, })).filter(accepted => !testData.checkedInUsers[accepted.email])} />
                    ) : <div
                        className="container has-top-divider center-content"
                        style={{ paddingBottom: 10 }}
                    >
                        <h4 style={{ paddingTop: 100 }}>All Accepted Invitees have checked in!</h4>
                    </div>) : <div><EventTableAcceptedInvitees lockEvent={testData.lockEvent} eventID={id} testData={testData} isBYOB={testData.isBYOB} eventName={testData.eventName + " " + testData.date} data={Object.keys(testData.acceptedInvitees).map((key) => ({ ...testData.acceptedInvitees[key], email: key, drinks: Object.keys(testData.acceptedInvitees[key]).filter(newKey => newKey !== "No Drinks").map(drink => drink + ': ' + testData.acceptedInvitees[key][drink]).join(", ") }))} /></div>}

                </div> : <div
                    className="container has-top-divider center-content"
                    style={{ paddingBottom: 10 }}
                >
                    <h4 style={{ paddingTop: 100 }}>No Accepted Invitees yet!</h4>
                </div>)}

                {values.view === "checkedInUsers" && (testData.checkedInUsers && Object.keys(testData.checkedInUsers).length > 0 ? <div className="container center-content">

                    {testData.checkedOutUsers ? (Object.keys(testData.checkedInUsers).filter(accepted => !testData.checkedOutUsers[accepted]).length > 0 ? (testData.isBYOB ?
                        <EventTableCheckedIn eventID={id} lockEvent={testData.lockEvent} testData={testData} eventName={testData.eventName + " " + testData.date} isBYOB={testData.isBYOB} data={Object.keys(testData.checkedInUsers).filter(accepted => !testData.checkedOutUsers[accepted]).map((key) => ({ ...testData.checkedInUsers[key], email: key, drinks: Object.keys(testData.checkedInUsers[key].byobDrinks).filter(newKey => newKey !== "No Drinks").map(drink => drink + ': ' + testData.checkedInUsers[key].byobDrinks[drink]).join(", ") }))} />
                        :
                        <EventTableCheckedIn eventID={id} lockEvent={testData.lockEvent} testData={testData} eventName={testData.eventName + " " + testData.date} isBYOB={testData.isBYOB} data={Object.keys(testData.checkedInUsers).filter(accepted => !testData.checkedOutUsers[accepted]).map((key) => ({ ...testData.checkedInUsers[key], email: key }))} />

                    ) : <div
                        className="container has-top-divider center-content"
                        style={{ paddingBottom: 10 }}
                    >
                        <h4 style={{ paddingTop: 100 }}>All Checked In Users have checked out!</h4>
                    </div>) : <div
                        className="container has-top-divider center-content"
                        style={{ paddingBottom: 10 }}
                    >
                        <EventTableCheckedIn eventID={id} lockEvent={testData.lockEvent} eventName={testData.eventName + " " + testData.date} testData={testData} isBYOB={testData.isBYOB} data={Object.keys(testData.checkedInUsers).map((key) => ({ ...testData.checkedInUsers[key], email: key, drinks: Object.keys(testData.checkedInUsers[key].byobDrinks).filter(newKey => newKey !== "No Drinks").map(drink => drink + ': ' + testData.checkedInUsers[key].byobDrinks[drink]).join(", ") }))} />
                    </div>}</div> : <div
                        className="container has-top-divider center-content"
                        style={{ paddingBottom: 10 }}
                    >
                    <h4 style={{ paddingTop: 100 }}>No Checked In Users yet!</h4>
                </div>)}

                {values.view === "checkedOutUsers" && (testData.checkedOutUsers && Object.keys(testData.checkedOutUsers).length > 0 ? <div className="container center-content">
                    <EventTableCheckedOut eventID={id} testData={testData} eventName={testData.eventName + " " + testData.date} isBYOB={testData.isBYOB} data={Object.keys(testData.checkedOutUsers).map((key) => ({ ...testData.checkedOutUsers[key], email: key }))} />

                </div> : <div
                    className="container has-top-divider center-content"
                    style={{ paddingBottom: 10 }}
                >
                    <h4 style={{ paddingTop: 100 }}>No Checked Out Users yet!</h4>
                </div>)}





                {values.view === "eventStaff" && (testData.eventStaff && Object.keys(testData.eventStaff).length > 0 ? <div className="container center-content">
                    <EventTableStaff eventID={id} eventName={testData.eventName + " " + testData.date} isBYOB={testData.isBYOB} data={testData.eventStaff} testData={testData} />
                </div> : <div
                    className="container has-top-divider center-content"
                    style={{ paddingBottom: 10 }}
                >
                    <h4 style={{ paddingTop: 100 }}>No Event Staff assigned!</h4>
                </div>)}

            </div>
                ))}
        </div>
        </React.Fragment >
    );
}

export default EventLiveView;

function GlobalFilterAcceptedInvitees({ globalFilter, setGlobalFilter }) {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);
    return (
        <input
            className="divide-y"
            style={{
                width: "25%",
                borderColor: "gray",
                borderWidth: 1,
                borderRadius: 10,
                padding: 5,
                marginBottom: 20,
            }}
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            placeholder="Search..."
        ></input>
    );
}

function EventTableAcceptedInvitees({ data, eventName, testData, eventID, isBYOB, lockEvent }) {
    const columns = useMemo(() => [
        {
            Header: "Email",
            accessor: "email",
        },
    ]);

    const columnsBYOB = useMemo(() => [
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "Drinks",
            accessor: "drinks",
        },
    ]);

    const { user, checkInAtEvent, markCSVExportTime } = useAppContext();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state,
        visibleColumns,
        prepareRow,
        setGlobalFilter,
        preGlobalFilteredRows,
    } = useTable(
        {
            columns: isBYOB ? columnsBYOB : columns,
            data,
            autoResetHiddenColumns: false,
        },
        useFilters,
        useGlobalFilter
    );

    const onDownloadClick = (e) => {
        e.preventDefault();
        var csv = Papa.unparse(data, { columns: ["email", "drinks"] })
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, eventName + ' Accepted Invitees.csv');
        } else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', eventName + ' Accepted Invitees.csv');
        tempLink.click();
        markCSVExportTime({eventId: eventID, csvType: "Accepted Invitees"})
    }

    const onCheckInAcceptedSubmit = (e, email, drinks) => {
        e.preventDefault()
        checkInAtEvent(eventID, email, drinks)
    }

    return (
        <>
            <div className="overflow-x-auto relative ">
                <h4>Accepted Invitees</h4>
                <table
                    className="table-auto w-full divide-y divide-slate-200"
                    {...getTableProps()}
                >
                    <thead>

                        <tr>
                            <th
                                colSpan={visibleColumns.length + 1}
                                className="center-content container"
                            >
                                <GlobalFilterAcceptedInvitees
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                                <Button onClick={onDownloadClick} size="sm">Download CSV</Button>
                            </th>
                        </tr>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}

                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr key={i}>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div className="font-bold text-slate-800">
                                            <div style={{ textAlign: "center" }}>
                                                {row.cells[0].value}
                                            </div>
                                        </div>
                                    </td>
                                    {isBYOB &&
                                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div style={{ textAlign: "center" }}>
                                                {row.cells[1].value}
                                            </div>
                                        </td>
                                    }

                                    {user && user.isAuthorized && (
                                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">

                                            <Button disabled={lockEvent} onClick={(e) => { if (isBYOB) { onCheckInAcceptedSubmit(e, row.cells[0].value, testData.acceptedInvitees[row.cells[0].value]) } else { onCheckInAcceptedSubmit(e, row.cells[0].value, {}) } }} className="mt-15" size="sm" color="secondary">
                                                Check In
                                            </Button>

                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

function GlobalFilterCheckedIn({ globalFilter, setGlobalFilter }) {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);
    return (
        <input
            className="divide-y"
            style={{
                width: "25%",
                borderColor: "gray",
                borderWidth: 1,
                borderRadius: 10,
                padding: 5,
                marginBottom: 20,
            }}
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            placeholder="Search..."
        ></input>
    );
}

function EventTableCheckedIn({ data, eventName, isBYOB, lockEvent, eventID }) {
    const columns = useMemo(() => [
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "First Name",
            accessor: "scannedFirstName",
        },
        {
            Header: "Last Name",
            accessor: "scannedLastName",
        },
        {
            Header: "House",
            accessor: "scannedHouse",
        },
        {
            Header: "Scanned At",
            accessor: "scannedTime",
        },
    ]);


    const columnsBYOB = useMemo(() => [
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "First Name",
            accessor: "scannedFirstName",
        },
        {
            Header: "Last Name",
            accessor: "scannedLastName",
        },
        {
            Header: "House",
            accessor: "scannedHouse",
        },
        {
            Header: "Scanned At",
            accessor: "scannedTime",
        },
        {
            Header: "Drinks",
            accessor: "drinks",
        },
    ]);

    const { user, checkOutAtEvent, markCSVExportTime } = useAppContext();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state,
        visibleColumns,
        prepareRow,
        setGlobalFilter,
        preGlobalFilteredRows,
    } = useTable(
        {
            columns: isBYOB ? columnsBYOB : columns,
            data,
            autoResetHiddenColumns: false,
        },
        useFilters,
        useGlobalFilter
    );

    const onDownloadClick = (e) => {
        e.preventDefault();

        const columnsBYOB = ["scannedEventID", "scannedInviteType", "email", "scannedFirstName", "scannedLastName", "scannedHouse", "scannedSchool", "scannedTime", "drinks", "scannerFirstName", "scannerLastName", "scannerEmail", "scannerHouse"]
        const columns = ["scannedEventID", "scannedInviteType", "email", "scannedFirstName", "scannedLastName", "scannedHouse", "scannedSchool", "scannedTime", "scannerFirstName", "scannerLastName", "scannerEmail", "scannerHouse"]
        var csv = Papa.unparse(data, { columns: isBYOB ? columnsBYOB : columns })
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, eventName + ' Checked In Users.csv');
        } else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', eventName + ' Checked In Users.csv');
        tempLink.click();
        markCSVExportTime({eventId: eventID, csvType: "Checked In Users"})
    }

    return (
        <>
            <div className="overflow-x-auto relative ">
                <h4>Checked In Users</h4>
                <table
                    className="table-auto w-full divide-y divide-slate-200"
                    {...getTableProps()}
                >
                    <thead>

                        <tr>
                            <th
                                colSpan={visibleColumns.length + 1}
                                className="center-content container first:pl-5 last:pr-5"
                            >
                                <GlobalFilterCheckedIn
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                                <Button onClick={onDownloadClick} size="sm">Download CSV</Button>
                            </th>
                        </tr>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}

                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr key={i}>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div className="font-bold text-slate-800">
                                            <div style={{ textAlign: "center" }}>
                                                {row.cells[0].value}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div style={{ textAlign: "center" }}>
                                            {row.cells[1].value}
                                        </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div style={{ textAlign: "center" }}>
                                            {row.cells[2].value}
                                        </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div style={{ textAlign: "center" }}>
                                            {row.cells[3].value}
                                        </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div style={{ textAlign: "center" }}>
                                            {row.cells[4].value}
                                        </div>
                                    </td>

                                    {isBYOB &&
                                        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                            <div style={{ textAlign: "center" }}>
                                                {row.cells[5].value}
                                            </div>
                                        </td>
                                    }
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">

                                        <Button disabled={lockEvent} onClick={(e) => { e.preventDefault(); checkOutAtEvent({ eventId: data[i].scannedEventID, userData: data[i] }) }} className="mt-15" size="sm" color="secondary">
                                            Check Out
                                        </Button>

                                    </td>


                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

function GlobalFilterStaff({ globalFilter, setGlobalFilter }) {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);
    return (
        <input
            className="divide-y"
            style={{
                width: "25%",
                borderColor: "gray",
                borderWidth: 1,
                borderRadius: 10,
                padding: 5,
                marginBottom: 20,
            }}
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            placeholder="Search..."
        ></input>
    );
}

function EventTableStaff({ data, eventName, eventID }) {
    const columns = useMemo(() => [
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "First Name",
            accessor: "firstName",
        },
        {
            Header: "Last Name",
            accessor: "lastName",
        },
        {
            Header: "Phone Number",
            accessor: "phoneNumber",
        },
        {
            Header: "Role",
            accessor: "role",
        },

    ]);


    const { markCSVExportTime } = useAppContext()


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state,
        visibleColumns,
        prepareRow,
        setGlobalFilter,
        preGlobalFilteredRows,
    } = useTable(
        {
            columns,
            data,
            autoResetHiddenColumns: false,
        },
        useFilters,
        useGlobalFilter
    );

    const onDownloadClick = (e) => {
        e.preventDefault();
        var csv = Papa.unparse(data)
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, eventName + ' Staff.csv');
        } else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', eventName + ' Staff.csv');
        tempLink.click();
        markCSVExportTime({eventId: eventID, csvType: "Staff"})
    }

    return (
        <>
            <div className="overflow-x-auto relative ">
                <h4>Event Staff</h4>
                <table
                    className="table-auto w-full divide-y divide-slate-200"
                    {...getTableProps()}
                >
                    <thead>

                        <tr>
                            <th
                                colSpan={visibleColumns.length + 1}
                                className="center-content container first:pl-5 last:pr-5"
                            >
                                <GlobalFilterStaff
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                                <Button onClick={onDownloadClick} size="sm">Download CSV</Button>
                            </th>
                        </tr>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}

                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr key={i}>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div className="font-bold text-slate-800">
                                            <div style={{ textAlign: "center" }}>
                                                {row.cells[0].value}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div style={{ textAlign: "center" }}>
                                            {row.cells[1].value}
                                        </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div style={{ textAlign: "center" }}>
                                            {row.cells[2].value}
                                        </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div style={{ textAlign: "center" }}>
                                            {row.cells[3].value}
                                        </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div style={{ textAlign: "center" }}>
                                            {row.cells[4].value}
                                        </div>
                                    </td>




                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

function GlobalFilterCheckedOut({ globalFilter, setGlobalFilter }) {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);
    return (
        <input
            className="divide-y"
            style={{
                width: "25%",
                borderColor: "gray",
                borderWidth: 1,
                borderRadius: 10,
                padding: 5,
                marginBottom: 20,
            }}
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            placeholder="Search..."
        ></input>
    );
}

function EventTableCheckedOut({ data, eventName, isBYOB, eventID }) {
    const columns = useMemo(() => [
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "First Name",
            accessor: "scannedFirstName",
        },
        {
            Header: "Last Name",
            accessor: "scannedLastName",
        },
        {
            Header: "House",
            accessor: "scannedHouse",
        },
        {
            Header: "Scanned At",
            accessor: "scannedTime",
        },
    ]);


    const columnsBYOB = useMemo(() => [
        {
            Header: "Email",
            accessor: "email",
        },
        {
            Header: "First Name",
            accessor: "scannedFirstName",
        },
        {
            Header: "Last Name",
            accessor: "scannedLastName",
        },
        {
            Header: "House",
            accessor: "scannedHouse",
        },
        {
            Header: "Scanned At",
            accessor: "scannedTime",
        },
    ]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state,
        visibleColumns,
        prepareRow,
        setGlobalFilter,
        preGlobalFilteredRows,
    } = useTable(
        {
            columns: isBYOB ? columnsBYOB : columns,
            data,
            autoResetHiddenColumns: false,
        },
        useFilters,
        useGlobalFilter
    );

    const { uncheckOutAtEvent, markCSVExportTime } = useAppContext();

    const onDownloadClick = (e) => {
        e.preventDefault();

        const columnsBYOB = ["scannedEventID", "scannedInviteType", "email", "scannedFirstName", "scannedLastName", "scannedHouse", "scannedSchool", "scannedTime", "drinks", "scannerFirstName", "scannerLastName", "scannerEmail", "scannerHouse"]
        const columns = ["scannedEventID", "scannedInviteType", "email", "scannedFirstName", "scannedLastName", "scannedHouse", "scannedSchool", "scannedTime", "scannerFirstName", "scannerLastName", "scannerEmail", "scannerHouse"]
        var csv = Papa.unparse(data, { columns: isBYOB ? columnsBYOB : columns })
        var csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        var csvURL = null;
        if (navigator.msSaveBlob) {
            csvURL = navigator.msSaveBlob(csvData, eventName + ' Checked Out Users.csv');
        } else {
            csvURL = window.URL.createObjectURL(csvData);
        }
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', eventName + ' Checked Out Users.csv');
        tempLink.click();
        markCSVExportTime({eventId: eventID, csvType: "Checked Out Users"})
    }

    return (
        <>
            <div className="overflow-x-auto relative ">
                <h4>Checked Out Users</h4>
                <table
                    className="table-auto w-full divide-y divide-slate-200"
                    {...getTableProps()}
                >
                    <thead>

                        <tr>
                            <th
                                colSpan={visibleColumns.length + 1}
                                className="center-content container first:pl-5 last:pr-5"
                            >
                                <GlobalFilterCheckedOut
                                    preGlobalFilteredRows={preGlobalFilteredRows}
                                    globalFilter={state.globalFilter}
                                    setGlobalFilter={setGlobalFilter}
                                />
                                <Button onClick={onDownloadClick} size="sm">Download CSV</Button>
                            </th>
                        </tr>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}

                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr key={i}>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div className="font-bold text-slate-800">
                                            <div style={{ textAlign: "center" }}>
                                                {row.cells[0].value}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div style={{ textAlign: "center" }}>
                                            {row.cells[1].value}
                                        </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div style={{ textAlign: "center" }}>
                                            {row.cells[2].value}
                                        </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div style={{ textAlign: "center" }}>
                                            {row.cells[3].value}
                                        </div>
                                    </td>
                                    <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                        <div style={{ textAlign: "center" }}>
                                            {row.cells[4].value}
                                        </div>
                                    </td>





                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}