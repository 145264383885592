export const GET_HOUSES_BEGIN = "GET_HOUSES_BEGIN";
export const GET_HOUSES_END = "GET_HOUSES_END";
export const GET_HOUSES_ERROR = "GET_HOUSES_ERROR";

export const CREATE_EVENT_BEGIN = "CREATE_EVENT_BEGIN";
export const CREATE_EVENT_END = "CREATE_EVENT_END";
export const CREATE_EVENT_ERROR = "CREATE_EVENT_ERROR";

export const UPDATE_EVENT_BEGIN = "UPDATE_EVENT_BEGIN";
export const UPDATE_EVENT_END = "UPDATE_EVENT_END";
export const UPDATE_EVENT_ERROR = "UPDATE_EVENT_ERROR";

export const GET_EVENTS_BEGIN = "GET_EVENTS_BEGIN";
export const GET_EVENTS_END = "GET_EVENTS_END";
export const GET_EVENTS_ERROR = "GET_EVENTS_ERROR";

export const GET_EVENT_BEGIN = "GET_EVENT_BEGIN";
export const GET_EVENT_END = "GET_EVENT_END";
export const GET_EVENT_ERROR = "GET_EVENT_ERROR";

export const CHANGE_VALUE = "CHANGE_VALUE";
export const POPULATE_EVENT = "POPULATE_EVENT";
export const CLEAR_VALUES = "CLEAR_VALUES";

export const UPLOAD_MEMBERS_BEGIN = "UPLOAD_MEMBERS_BEGIN";
export const UPLOAD_MEMBERS_END = "UPLOAD_MEMBERS_END";
export const UPLOAD_MEMBERS_ERROR = "UPLOAD_MEMBERS_ERROR"

export const LOGOUT_BEGIN = "LOGOUT_BEGIN";
export const LOGOUT_END = "LOGOUT_END";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const LOGIN_BEGIN = "LOGIN_BEGIN"
export const LOGIN_END = "LOGIN_END";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const FILL_DEFAULT_EVENT = "FILL_DEFAULT_EVENT";

export const SEND_RESET_PASSWORD_EMAIL_BEGIN = "SEND_RESET_PASSWORD_EMAIL_BEGIN";
export const SEND_RESET_PASSWORD_EMAIL_END = "SEND_RESET_PASSWORD_EMAIL_END";
export const SEND_RESET_PASSWORD_EMAIL_ERROR = "SEND_RESET_PASSWORD_EMAIL_ERROR";

export const RESET_PASSWORD_BEGIN = "RESET_PASSWORD_BEGIN";
export const RESET_PASSWORD_END = "RESET_PASSWORD_END";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const VERIFY_EMAIL_BEGIN = "VERIFY_EMAIL_BEGIN";
export const VERIFY_EMAIL_END = "VERIFY_EMAIL_END";
export const VERIFY_EMAIL_ERROR = "VERIFY_EMAIL_ERROR";

export const CLEAR_EVENT = "CLEAR_EVENT";

export const GET_MEMBERS_BEGIN = "GET_MEMBERS_BEGIN";
export const GET_MEMBERS_END = "GET_MEMBERS_END";
export const GET_MEMBERS_ERROR = "GET_MEMBERS_ERROR";

export const GET_LIVE_EVENT = "GET_LIVE_EVENT";

export const ADMIN_GET_EVENTS_BEGIN = "ADMIN_GET_EVENTS_BEGIN";
export const ADMIN_GET_EVENTS_END = "ADMIN_GET_EVENTS_END";
export const ADMIN_GET_EVENTS_ERROR = "ADMIN_GET_EVENTS_ERROR";

export const ADMIN_CREATE_BEGIN = "ADMIN_CREATE_BEGIN"
export const ADMIN_CREATE_END = "ADMIN_CREATE_END"
export const ADMIN_CREATE_ERROR = "ADMIN_CREATE_ERROR"

export const ADMIN_CREATE_AUTH_USER_BEGIN = "ADMIN_CREATE_AUTH_USER_BEGIN"
export const ADMIN_CREATE_AUTH_USER_END = "ADMIN_CREATE_AUTH_USER_END"
export const ADMIN_CREATE_AUTH_USER_ERROR = "ADMIN_CREATE_AUTH_USER_ERROR"

export const ADMIN_GET_SCHOOLS_BEGIN = "ADMIN_GET_SCHOOLS_BEGIN"
export const ADMIN_GET_SCHOOLS_END = "ADMIN_GET_SCHOOLS_END"
export const ADMIN_GET_SCHOOLS_ERROR = "ADMIN_GET_SCHOOLS_ERROR"







 