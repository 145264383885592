import React, { useState, useEffect } from "react";
import { useAppContext } from "../context/appContext";
import classNames from "classnames";
import SectionHeader from "../components/sections/partials/SectionHeader";
import Input from "../components/elements/Input";
import Button from "../components/elements/Button";
import { useParams, useLocation, Link, useHistory } from "react-router-dom";

const initialState = { newPassword: "", email: "" }

export default function AuthAction({ props }) {

    const history = useHistory()
    const [values, setValues] = useState(initialState)
    const { user, isLoading, errorMessage, successMessage, handleChange, resetPassword, verifyEmail } = useAppContext()
    let location = useLocation()


    function getParameterByName(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    const outerClasses = classNames(
        "signin section",
        true && "has-top-divider",
        true && "has-bottom-divider",
        false && "has-bg-color",
        false && "invert-color"
    );

    const innerClasses = classNames(
        "signin-inner section-inner",
        true && "has-top-divider",
        true && "has-bottom-divider"
    );


    const handleInputChange = (e) => {
        e.preventDefault();
        setValues({ ...values, [e.target.name]: e.target.value })
    }
    const mode = getParameterByName('mode');
    // Get the one-time code from the query parameter.
    const actionCode = getParameterByName('oobCode');
    // (Optional) Get the continue URL from the query parameter if available.
    const continueUrl = getParameterByName('continueUrl');
    // (Optional) Get the language code if available.
    const lang = getParameterByName('lang') || 'en';

    useEffect(() => {
		(async () => {
            if (mode === "verifyEmail") {
			    verifyEmail(actionCode, continueUrl ? continueUrl : "", lang)
            }
		})();
	}, []);

    const sectionHeader = {
        title: mode === "resetPassword" ? "Reset Password" : "Verify Email",
    };




    const onSubmit = async (e) => {
        e.preventDefault()
        handleChange("errorMessage", "")
        const { newPassword, email } = values


        switch (mode) {
            case 'resetPassword':
                if (!newPassword) {
                    handleChange("errorMessage", "Please enter a password!")
                    return;
                }
                await resetPassword(newPassword, actionCode, lang)
                if (successMessage === "Password successfully reset!") {
                    setTimeout(() => { history.push("/login") }, 3000)
                }
                break;
            case 'verifyEmail':
                if (!email) {
                    handleChange("errorMessage", "Please enter your email address!")
                    return;
                }
                break;
            default:
            // Error: invalid mode.
        }
    }








    return (
        <section className={outerClasses}>
            <div className="container">
                <div className={innerClasses}>
                    <SectionHeader
                        tag="h1"
                        data={sectionHeader}
                        className="center-content"
                    />
                    <div className="tiles-wrap">
                        <div className="tiles-item">
                            <div className="tiles-item-inner">
                                <form>
                                    <fieldset>
                                        <div className="mb-12">
                                            {mode === "resetPassword" &&
                                                <Input
                                                    type="password"
                                                    label="New Password"
                                                    name="newPassword"
                                                    placeholder="Password"
                                                    labelHidden
                                                    value={values.newPassword}
                                                    onChange={handleInputChange}
                                                    required
                                                    disabled={isLoading}
                                                />}
                                           
                                        </div>
                                        <div className="mt-24 mb-32">
                                        {mode === "resetPassword" &&
                                            <Button color="primary" wide onClick={onSubmit} disabled={isLoading}>
                                                Reset Password
                                            </Button>
                                        }
                                        {isLoading &&
                                            <Button loading={isLoading}>Loading</Button>
                                        }
                                        </div>

                                        <div className="mt-24 mb-32">

                                            <Link to="/login">
                                                <Button color="secondary" wide disabled={isLoading}>
                                                    To Login
                                                </Button>
                                            </Link>
                                        </div>



                                        <div
                                            className="mt-24 mb-32"
                                            style={{ background: "white", borderRadius: 15 }}
                                        >
                                            <p
                                                style={
                                                    errorMessage.length !== 0
                                                        ? {
                                                            padding: 5,
                                                            color: "red",
                                                            fontSize: 15,
                                                            textAlign: "center",
                                                        }
                                                        : {}
                                                }
                                            >
                                                {errorMessage}
                                            </p>
                                        </div>

                                        <div
                                            className="mt-24 mb-32"
                                            style={{ background: "white", borderRadius: 15 }}
                                        >
                                            <p
                                                style={
                                                    successMessage.length !== 0
                                                        ? {
                                                            padding: 5,
                                                            color: "green",
                                                            fontSize: 15,
                                                            textAlign: "center",
                                                        }
                                                        : {}
                                                }
                                            >
                                                {successMessage}
                                            </p>
                                        </div>

                                    </fieldset>
                                </form>
                                <div className="signin-bottom has-top-divider">
                                    <div className="pt-32 text-xs center-content text-color-low">
                                        You must have an account to use this site. Create your account on the app!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
