import React from "react";
import HeroFull from "../components/sections/HeroFull";
import FeaturesTiles from "../components/sections/FeaturesTiles";
import { Redirect } from "react-router-dom";

function PrivacyPolicy() {
	return (
		<React.Fragment>
			<section className="hero section center-content has-top-divider">
				<div className="container-sm">
					<div className="hero-inner section-inner">
						<div className="hero-content">
							<h1
								className="mt-0 mb-32 reveal-from-bottom "
								data-reveal-delay="200"
							>
								FratPak - Privacy Policy
							</h1>
							<div className="">
								<iframe
									src={require("../assets/files/FratPakPrivacyPolicy.pdf")}
									width={"100%"}
									height={900}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</React.Fragment>
	);
}

export default PrivacyPolicy;
