import React from "react";
import { withRouter, Switch } from "react-router-dom";
import AppRoute from "./utils/AppRoute";
import ScrollReveal from "./utils/ScrollReveal";
import { withUser } from "./components/layout/withUser";
// Layouts
import LayoutDefault from "./layouts/LayoutDefault";
import LayoutAlternative from "./layouts/LayoutAlternative";
import LayoutSignin from "./layouts/LayoutSignin";

// Views
import Home from "./views/Home";
import Secondary from "./views/Secondary";
import Login from "./views/Login";
import Signup from "./views/Signup";
import EventListView from "./views/Event/EventListView";
import EventCreateView from "./views/Event/EventCreateView";
import EventDetailView from "./views/Event/EventDetailView";
import EventLiveView from "./views/Event/EventLiveView";
import HouseMemberDataUpload from "./views/HouseMemberDataUpload";
import AuthAction from "./views/AuthAction";
import MemberListView from "./views/MemberLookup";
//import PrivacyPolicy from "./views/PrivacyPolicy";
import TermsOfService from "./views/TermsOfService";
import PrivacyPolicy from "./views/PrivacyPolicy";
import AdminEventListView from "./views/Admin/AdminEventListView";
import AdminCreateView from "./views/Admin/AdminCreateView";
import AdminAddAuthorizedUser from "./views/Admin/AdminAddAuthorizedUser";

class App extends React.Component {
	componentDidMount() {
		document.body.classList.add("is-loaded");
		this.refs.scrollReveal.init();
	}

	// Route change
	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.refs.scrollReveal.init();
		}
	}

	render() {
		const user = this.props.user;
		return (
			<ScrollReveal
				ref="scrollReveal"
				children={() => (
					<Switch>
						<AppRoute exact path="/" component={Home} layout={LayoutDefault} />
						<AppRoute
							exact
							path="/events"
							component={EventListView}
							layout={LayoutDefault}
						/>
						<AppRoute
							path="/events/detail/:id"
							component={EventDetailView}
							layout={LayoutDefault}
						/>
						<AppRoute
							path="/events/live/:id"
							component={EventLiveView}
							layout={LayoutDefault}
						/>
						{user && user.isAuthorized && (
							<AppRoute
								exact
								path="/events/create"
								component={EventCreateView}
								layout={LayoutDefault}
							/>
						)}


						<AppRoute
							exact
							path="/secondary"
							component={Secondary}
							layout={LayoutAlternative}
						/>
						<AppRoute
							exact
							path="/login"
							component={Login}
							layout={LayoutSignin}
						/>
						<AppRoute
							exact
							path="/signup"
							component={Signup}
							layout={LayoutSignin}
						/>
						<AppRoute
							exact
							path="/member/upload"
							component={HouseMemberDataUpload}
							layout={LayoutDefault}
						/>
						<AppRoute
							path={`/mgmt/action`}
							component={AuthAction}
							layout={LayoutSignin}
						/>
						<AppRoute
							exact
							path="/member/list"
							component={MemberListView}
							layout={LayoutDefault}
						/>
						<AppRoute
							exact
							path="/terms-of-service"
							component={TermsOfService}
							layout={LayoutDefault}
						/>
						<AppRoute
							exact
							path="/privacy-policy"
							component={PrivacyPolicy}
							layout={LayoutDefault}
						/>
						{
							user && user.admin &&
							(
								<>
									<AppRoute
										exact
										path="/admin/events"
										component={AdminEventListView}
										layout={LayoutDefault}
									/>
									<AppRoute
										exact
										path="/admin/create"
										component={AdminCreateView}
										layout={LayoutDefault}
									/>
									<AppRoute
										exact
										path="/admin/user/create"
										component={AdminAddAuthorizedUser}
										layout={LayoutDefault}
									/>
								</>
							)
						}
					</Switch>
				)}
			/>
		);
	}
}

export default withUser(withRouter((props) => <App {...props} />));
