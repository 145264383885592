// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
	getFirestore,
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	where,
	addDoc,
	updateDoc,
	setDoc,
	onSnapshot,
	connectFirestoreEmulator,
} from "firebase/firestore";
import {
	getAuth,
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	verifyPasswordResetCode,
	signOut,
	sendPasswordResetEmail,
	confirmPasswordReset,
	applyActionCode,
	sendEmailVerification,
} from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: process.env.REACT_APP_apiKey,
	authDomain: process.env.REACT_APP_authDomain,
	projectId: process.env.REACT_APP_projectId,
	storageBucket: process.env.REACT_APP_storageBucket,
	messagingSenderId: process.env.REACT_APP_messagingSenderId,
	appId: process.env.REACT_APP_appId,
	measurementId: process.env.REACT_APP_measurementId,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const auth = getAuth(app);

//DB references
const usersDB = collection(db, "Users");
const eventsDB = collection(db, "Events");
const housesDB = collection(db, "Houses");
const schoolsDB = collection(db, "Schools");

//auth functions
export async function login(username, password) {
	try {
		const result = await signInWithEmailAndPassword(auth, username, password);
		const email = result.user.email;
		const userDocRef = doc(usersDB, email);
		const userDocSnap = await getDoc(userDocRef);
		const userData = userDocSnap.data()
		if (!userData.isAuthorized) {
			logout()
			return { error: true, code: 1, message: "Error: This user is not authorized to access this page." };
		}
		localStorage.setItem("user", JSON.stringify(userDocSnap.data()));
		return { error: false, user: userDocSnap.data() };
	} catch (e) {
		let errorMessage = "";
		if (e.message === "Firebase: Error (auth/wrong-password).") {
			errorMessage = "Error: Incorrect password."
		} else {
			errorMessage = "Error: User " + username + " not found."
		}
		return { error: true, code: e.code, message: errorMessage };
	}
}

export async function logout() {
	try {
		const result = await signOut(auth);
		await localStorage.removeItem("user");
		return { error: false };
	} catch (e) {

		return { error: true, code: e.code, message: e.message };
	}
}

export async function resetPasswordEmail(email) {
	try {
		const result = await sendPasswordResetEmail(auth, email);

		return { error: false };
	} catch (e) {
		return { error: true, code: e.code, message: e.message };
	}
}

export async function handleResetPassword(actionCode, continueUrl, lang, newPassword) {
	// Localize the UI to the selected language as determined by the lang
	// parameter.

	// Verify the password reset code is valid.
	try {
		const result = await verifyPasswordResetCode(auth, actionCode)
		const result2 = await confirmPasswordReset(auth, actionCode, newPassword)
		return { error: false }
	} catch (e) {
		return { error: true, code: e.code ? e.code : 0, message: e.message };
	}

}



export async function handleVerifyEmail(actionCode, continueUrl, lang) {
	// Localize the UI to the selected language as determined by the lang
	// parameter.
	// Try to apply the email verification code.
	try {
		const result = applyActionCode(auth, actionCode)

		return { error: false }
	} catch (e) {
		return { error: true, code: e.code ? e.code : 0, message: e.message };
	}

}

export async function getUsersDB() {
	try {

		const q = query(usersDB)
		const result = await getDocs(q)
		const q2 = query(schoolsDB)
		const result2 = await getDocs(q2)
		return { error: false, members: result.docs, schools: result2.docs }

	} catch (e) {
		return { error: true, code: e.code, message: e.message };
	}
}


//event functions
export async function getEventsForUser() {
	try {
		const user = JSON.parse(localStorage.getItem("user"));
		const house = user.greekHouse.realName;
		const q = query(eventsDB, where("houseHolding", "==", house));
		const qSnap = await getDocs(q);
		return { error: false, events: qSnap.docs };
	} catch (e) {
		return { error: true, code: e.code, message: e.message };
	}
}

export async function getEventByIDForUser(id) {
	try {
		const docRef = doc(eventsDB, id);
		const docSnap = await getDoc(docRef);
		return { error: false, event: docSnap.data() };
	} catch (e) {
		return { error: true, code: e.code, message: e.message };
	}
}

export async function getLiveUpdatesForEvent(id) {
	try {
		const unsub = onSnapshot(doc(eventsDB, id), (doc) => doc.data())
		return { error: false, eventSubscribe: unsub };
	} catch (e) {
		return { error: true, code: e.code, message: e.message };
	}
}

async function updateBlackballListForHouse(house, school, blackball) {
	try {
		const q = query(schoolsDB, where("realName", "==", school));
		const qSnap = await getDocs(q);
		const docRef = qSnap.docs[0].ref
		const docData = qSnap.docs[0].data()
		const houses = docData.Houses
		let houseObj = houses[house]

		houseObj["houseBlackball"] = blackball


		await updateDoc(docRef, { ...docData, houses: { ...houses, houseObj } })

		return { error: false }
	} catch (e) {
		throw e
	}



}

export async function createEvent(eventData) {
	try {
		const user = JSON.parse(localStorage.getItem("user"));
		const house = user.greekHouse.realName;
		const school = user.school;
		const {
			eventName,
			eventLocation,
			eventDescription,
			date,
			startTime,
			endTime,
			housesList,
			eventStaff,
			isBYOB,
			allowGuests,
			isSelfService,
			maxGuestCount,
			eventBlackball,
		} = eventData;
		const data = {
			eventName: eventName,
			eventLocation: eventLocation,
			eventDescription: eventDescription,
			date: date,
			startTime: startTime,
			endTime: endTime,
			housesList: housesList,
			eventStaff: eventStaff,
			houseHolding: house,
			isBYOB: isBYOB,
			allowGuests,
			maxGuestCount,
			eventBlackball,
			school,
			checkedInUsers: {},
			acceptedInvitees: {},
			checkedOutUsers: {},
			isSelfService
		};

		const docRef = await addDoc(eventsDB, data);
		var userRefs = []
		var userDocs = []
		var riskRefs = []
		var riskDocs = []

		// check for valid user docs
		for (let i = 0; i < eventStaff.length; i++) {
			if (eventStaff[i].role === "Check in Staff") {
				const email = eventStaff[i].email
				const userRef = doc(usersDB, email)
				userRefs.push(userRef)
				const userDoc = await getDoc(userRef)
				userDocs.push(userDoc)
				if (!userDoc._document) {
					return { error: true, code: 0, message: email + " is not connected to a user account." };
				}
			} else if (eventStaff[i].role === "Risk") {
				const email = eventStaff[i].email
				const userRef = doc(usersDB, email);
				riskRefs.push(userRef)
				const userDoc = await getDoc(userRef)
				riskDocs.push(userDoc)
				if (!userDoc._document) {
					return { error: true, code: 0, message: email + " is not connected to a user account." };
				}
			}
		}

		//update data in each user doc
		for (let i = 0; i < userRefs.length; i++) {
			const userData = { ...userDocs[i].data() }
			const authorizedEvents = userData.authorizedEvents ? [...userData.authorizedEvents, docRef.id] : [docRef.id]
			const newUserObj = { ...userData, authorizedEvents }
			updateDoc(userRefs[i], newUserObj)
		}

		for (let i = 0; i < riskRefs.length; i++) {
			const riskData = { ...riskDocs[i].data() }
			const managedEvents = riskData.managedEvents ? [...riskData.managedEvents, docRef.id] : [docRef.id]
			const newRiskObj = { ...riskData, managedEvents }
			updateDoc(riskRefs[i], newRiskObj)
		}

		await updateBlackballListForHouse(house, user.greekHouse.associatedSchool, eventBlackball)

		return { error: false, event: data };
	} catch (e) {
		return { error: true, code: e.code, message: e.message };
	}
}

export async function checkInUser({ eventId, email, drinks }) {

	try {
		const scanner = JSON.parse(localStorage.getItem("user"));
		//check for valid user doc 

		const userDoc = await getDoc(doc(usersDB, email))
		const userData = userDoc.data()
		if (!userData) {
			return { error: true, code: 105, message: "This email does not have any user information associated with it." };
		}
		const eventDoc = await getDoc(doc(eventsDB, eventId))
		const now = new Date()
		const timeString = String(now.getMinutes()).length === 1 ? (now.getMonth() + 1) + "-" + now.getDate() + '-' + now.getFullYear() + " " + now.getHours() + ":0" + now.getMinutes() : (now.getMonth() + 1) + "-" + now.getDate() + '-' + now.getFullYear() + " " + now.getHours() + ":" + now.getMinutes()
		const checkInObj = { byobDrinks: drinks, scannedEmail: email, scannedEventID: eventId, scannedFirstName: userData.firstName, scannedLastName: userData.lastName, scannedHouse: userData.greekHouse.realName, scannedInviteType: "site", scannedSchool: userData.school, scannerEmail: scanner.emailAddress, scannerFirstName: scanner.firstName, scannerLastName: scanner.lastName, scannerHouse: scanner.greekHouse.realName, scannedTime: timeString }

		let eventData = eventDoc.data()
		if (!eventData.checkedInUsers) {
			eventData.checkedInUsers = {}
		}

		eventData["checkedInUsers"][email] = checkInObj



		await updateDoc(doc(eventsDB, eventId), eventData)

		return { error: false };
	} catch (e) {
		return { error: true, code: e.code, message: e.message };
	}




}

export async function checkInUserForm({ eventId, email, drinks, userData }) {

	try {
		const scanner = JSON.parse(localStorage.getItem("user"));
		//check for valid user doc 

		if (!userData) {
			return { error: true, code: 105, message: "This email does not have any user information associated with it." };
		}
		const eventDoc = await getDoc(doc(eventsDB, eventId))
		const now = new Date()
		const timeString = String(now.getMinutes()).length === 1 ? (now.getMonth() + 1) + "-" + now.getDate() + '-' + now.getFullYear() + " " + now.getHours() + ":0" + now.getMinutes() : (now.getMonth() + 1) + "-" + now.getDate() + '-' + now.getFullYear() + " " + now.getHours() + ":" + now.getMinutes()
		const checkInObj = { byobDrinks: drinks, scannedEmail: email, scannedEventID: eventId, scannedFirstName: userData.firstName, scannedLastName: userData.lastName, scannedHouse: userData.house, scannedInviteType: "site", scannedSchool: userData.school, scannerEmail: scanner.emailAddress, scannerFirstName: scanner.firstName, scannerLastName: scanner.lastName, scannerHouse: scanner.greekHouse.realName, scannedTime: timeString }

		let eventData = eventDoc.data()
		if (!eventData.checkedInUsers) {
			eventData.checkedInUsers = {}
		}

		eventData["checkedInUsers"][email] = checkInObj



		await updateDoc(doc(eventsDB, eventId), eventData)

		return { error: false };
	} catch (e) {
		return { error: true, code: e.code, message: e.message };
	}




}

export async function checkOut({ eventId, userData }) {
	try {
		const scanner = JSON.parse(localStorage.getItem("user"));
		if (!userData) {
			return { error: true, code: 105, message: "This email does not have any user information associated with it." };
		}
		const eventDoc = await getDoc(doc(eventsDB, eventId))
		const now = new Date()
		const timeString = String(now.getMinutes()).length === 1 ? (now.getMonth() + 1) + "-" + now.getDate() + '-' + now.getFullYear() + " " + now.getHours() + ":0" + now.getMinutes() : (now.getMonth() + 1) + "-" + now.getDate() + '-' + now.getFullYear() + " " + now.getHours() + ":" + now.getMinutes()
		
		const checkOutObj = { scannedEmail: userData.scannedEmail, scannedEventID: eventId, scannedFirstName: userData.scannedFirstName, scannedLastName: userData.scannedLastName, scannedHouse: userData.scannedHouse, scannedInviteType: "site", scannedSchool: userData.scannedSchool, scannerEmail: scanner.emailAddress, scannerFirstName: scanner.firstName, scannerLastName: scanner.lastName, scannerHouse: scanner.greekHouse.realName, scannedTime: timeString }
		let eventData = eventDoc.data()
		if (!eventData.checkedOutUsers) {
			eventData.checkedOutUsers = {}
		}

		eventData["checkedOutUsers"][userData.email] = checkOutObj



		await updateDoc(doc(eventsDB, eventId), eventData)

		return { error: false };
	} catch (e) {

		return { error: true, code: e.code, message: e.message };
	}
}

export async function uncheckOut({ eventId, userData }) {
	try {
		const eventDoc = await getDoc(doc(eventsDB, eventId))
		let eventData = eventDoc.data()
		delete eventData["checkedOutUsers"][userData.email]
		await updateDoc(doc(eventsDB, eventId), eventData)
		return { error: false };
	} catch (e) {

		return { error: true, code: e.code, message: e.message };
	}
}

export async function closeEvent({ eventId }) {
	try {
		const eventDoc = await getDoc(doc(eventsDB, eventId))
		let eventData = eventDoc.data()
		eventData.lockEvent = true;
		await updateDoc(doc(eventsDB, eventId), eventData)
		return { error: false };
	} catch (e) {

		return { error: true, code: e.code, message: e.message };
	}
}

export async function markCSVExport({ eventId, csvType }) {
	try {
		const eventDoc = await getDoc(doc(eventsDB, eventId))
		let eventData = eventDoc.data()
		const now = new Date()
		const timeString = String(now.getMinutes()).length === 1 ? (now.getMonth() + 1) + "-" + now.getDate() + '-' + now.getFullYear() + " " + now.getHours() + ":0" + now.getMinutes() : (now.getMonth() + 1) + "-" + now.getDate() + '-' + now.getFullYear() + " " + now.getHours() + ":" + now.getMinutes()
		
		if (!eventData.csvExportTimes) {
			eventData.csvExportTimes = {}
		}
		let newCsvExportTimes = eventData.csvExportTimes[csvType] ? eventData.csvExportTimes[csvType] : []

		newCsvExportTimes.push(timeString)

		eventData = {...eventData, csvExportTimes: {...eventData.csvExportTimes, [csvType]: newCsvExportTimes}};

		await updateDoc(doc(eventsDB, eventId), eventData)

		return { error: false };
	} catch (e) {

		return { error: true, code: e.code, message: e.message };
	}

}

export async function updateEvent(eventData, oldEventData) {

	const user = JSON.parse(localStorage.getItem("user"));
	const house = user.greekHouse.realName;

	const {
		eventName,
		eventLocation,
		eventDescription,
		date,
		startTime,
		endTime,
		housesList,
		eventStaff,
		isBYOB,
		allowGuests,
		maxGuestCount,
		eventBlackball,
		_id,
	} = eventData;

	const now = new Date()
	const timeString = String(now.getMinutes()).length === 1 ? (now.getMonth() + 1) + "-" + now.getDate() + '-' + now.getFullYear() + " " + now.getHours() + ":0" + now.getMinutes() : (now.getMonth() + 1) + "-" + now.getDate() + '-' + now.getFullYear() + " " + now.getHours() + ":" + now.getMinutes()
		


	const dateSplit = date.split("-");
	const year = dateSplit[0];
	const month = dateSplit[1];
	const day = dateSplit[2];
	const rejoinDate = []
	rejoinDate.push(month);
	rejoinDate.push(day);
	rejoinDate.push(year);
	const modDate = rejoinDate.join("-");

	const data = {
		eventName: eventName,
		eventLocation: eventLocation,
		eventDescription: eventDescription,
		date: modDate,
		startTime: startTime,
		endTime: endTime,
		housesList: housesList,
		eventStaff: eventStaff,
		isBYOB,
		allowGuests,
		maxGuestCount,
		eventBlackball,
		lastModified: timeString,
	};
	try {

		const docRef = doc(eventsDB, _id);
		await updateDoc(docRef, data);

		var userRefs = []
		var userDocs = []
		var riskRefs = []
		var riskDocs = []

		var userRefsDelete = []
		var userDocsDelete = []

		const oldEventStaff = oldEventData.eventStaff;

		for (let i = 0; i < oldEventStaff.length; i++) {
			if (eventStaff.filter(staff => staff.email === oldEventStaff[i].email).length === 0) {
				const email = oldEventStaff[i].email
				const userRef = doc(usersDB, email)
				userRefsDelete.push(userRef)
				const userDoc = await getDoc(userRef)
				userDocsDelete.push(userDoc)
				if (!userDoc._document) {
					return { error: true, code: 0, message: email + " is not connected to a user account." };
				}
			}
		}

		for (let i = 0; i < userRefsDelete.length; i++) {
			const userData = { ...userDocsDelete[i].data() }
			const docIndex = userData.authorizedEvents.indexOf(docRef.id)
			let newAuthorizedEvents = [...userData.authorizedEvents]
			const riskDocIndex = userData.managedEvents ? userData.managedEvents.indexOf(docRef.id) : -1
			let newManagedEvents = userData.managedEvents ? [...userData.managedEvents] : []
			if (docIndex !== -1) {
				newAuthorizedEvents.splice(docIndex, 1)
			}
			if (riskDocIndex !== -1) {
				newManagedEvents.splice(docIndex, 1)
			}
			const newUserObj = { ...userData, authorizedEvents: newAuthorizedEvents }
			updateDoc(userRefsDelete[i], newUserObj)
		}


		// check for valid user docs
		for (let i = 0; i < eventStaff.length; i++) {
			if (eventStaff[i].role === "Check in Staff") {
				const email = eventStaff[i].email
				const userRef = doc(usersDB, email)
				userRefs.push(userRef)
				const userDoc = await getDoc(userRef)
				userDocs.push(userDoc)
				if (!userDoc._document) {
					return { error: true, code: 0, message: email + " is not connected to a user account." };
				}
			} else if (eventStaff[i].role === "Risk") {
				const email = eventStaff[i].email
				const userRef = doc(usersDB, email);
				riskRefs.push(userRef)
				const userDoc = await getDoc(userRef)
				riskDocs.push(userDoc)
				if (!userDoc._document) {
					return { error: true, code: 0, message: email + " is not connected to a user account." };
				}
			}
		}

		//update data in each user doc
		for (let i = 0; i < userRefs.length; i++) {

			const userData = { ...userDocs[i].data() }
			const authorizedEvents = userData.authorizedEvents ? (userData.authorizedEvents.indexOf(docRef.id) === -1 ? [...userData.authorizedEvents, docRef.id] : userData.authorizedEvents) : [docRef.id]
			const newUserObj = { ...userData, authorizedEvents }
			updateDoc(userRefs[i], newUserObj)
		}

		for (let i = 0; i < riskRefs.length; i++) {
			const riskData = { ...riskDocs[i].data() }
			const managedEvents = riskData.managedEvents ? [...riskData.managedEvents, docRef.id] : [docRef.id]
			const newRiskObj = { ...riskData, managedEvents }
			updateDoc(riskRefs[i], newRiskObj)
		}

		await updateBlackballListForHouse(house, user.greekHouse.associatedSchool, eventBlackball)

		return { error: false, event: data };
	} catch (e) {
		return { error: true, code: e.code, message: e.message };
	}
}

//house functions
export async function getAllHousesForSchoolOfUser() {
	/*
	try {
		const user = JSON.parse(localStorage.getItem("user"));
		const school = user.school;
		if (!school) {
			return { error: false, houses: [] }
		}
		const q = query(housesDB, where("associatedSchool", "==", school));
		const qSnap = await getDocs(q);
		return { error: false, houses: qSnap.docs };
	} catch (e) {
		return { error: true, code: e.code, message: e.message };
	}
	*/
	try {
		const user = JSON.parse(localStorage.getItem("user"));
		const school = user.school;
		if (!school) {
			return { error: false, houses: [] }
		}
		const q = query(schoolsDB, where("realName", "==", school));
		const qSnap = await getDocs(q);
		const snapData = await qSnap.docs[0].data()

		return { error: false, houses: Object.keys(snapData.Houses) };
	} catch (e) {
		return { error: true, code: e.code, message: e.message };
	}
}



export async function getBlackballListForHouse(house, school) {
	try {
		const q = query(schoolsDB, where("realName", "==", school));
		const qSnap = await getDocs(q);
		const snapData = qSnap.docs[0].data()
		const houses = snapData.Houses
		const houseObject = houses[house]
		return ({ error: false, blackball: houseObject.houseBlackball })
	} catch (e) {
		return { error: true, code: e.code, message: e.message };
	}
}

export async function uploadMemberInfo(data) {
	try {
		const house = data.house
		const school = data.school
		const q = query(schoolsDB, where("realName", "==", school))
		const qSnap = await getDocs(q)
		const snapData = qSnap.docs[0].data()
		const houses = snapData.Houses
		if (!houses[house]) {
			throw new Error("House does not exist!")
		}
		const newHouse = houses[house]

		const members = data.members
		const houseObject = { associatedSchool: school, codableName: newHouse.codableName, isFraternity: newHouse.isFraternity, isSorority: newHouse.isSorority, realName: house }
		for (var i = 0; i < members.length; i++) {

			setDoc(doc(usersDB, members[i]["emailAddress"]), { ...members[i], greekHouse: { ...houseObject }, school, acceptedEvents: {}, declinedEvents: {}, eventInvitees: {}, invitedEvents: [], })
		}

		return { error: false };
	} catch (e) {
		return { error: true, code: e.code ? e.code : 0, message: e.message };
	}
}


//admin functions

export async function admin_getEvents() {
	try {
		const q = query(eventsDB)
		const qSnap = await getDocs(q)
		const snapData = qSnap.docs.map((event) => {return {...event.data(), id: event.id}});

		return {error: false, events: snapData};

	} catch (e) {
		return { error: true, code: e.code ? e.code : 0, message: e.message };
	}
}

export async function admin_getSchool(school) {
	try {
		const q = query(schoolsDB, where("realName", "==", school))
		const qSnap = await getDocs(q);
		const snapData = qSnap.docs[0].data()
		const q1 = query(usersDB, where("school", "==", school))
		const q1Snap = await getDocs(q1);
		const usersData = q1Snap.docs.map((event) => event.data());
		return {error: false, school: snapData, users: usersData}

	} catch (e) {
		return { error: true, code: e.code ? e.code : 0, message: e.message };
	}
}

export async function admin_addAdminUser(user) {
	try {
		await createUserWithEmailAndPassword(auth, user.email, user.password)
		await setDoc(doc(usersDB, user.email), {...user, admin: true, isAuthorized: true}, {merge: true});
		return {error: false, }
	} catch (e) {
		return { error: true, code: e.code ? e.code : 0, message: e.message };
	}
}

export async function admin_getSchools() {
	try {
		const q = query(schoolsDB)
		const qSnap = await getDocs(q)
		const snapData = qSnap.docs.map((school) => {return {...school.data()}})
		
		
		return {error: false, schools: snapData}
	} catch (e) {
		return { error: true, code: e.code ? e.code : 0, message: e.message };
	}
}

export async function admin_addAuthorizedUser(user) {
	try {
		//await createUserWithEmailAndPassword(auth, user.email, user.password)
		await setDoc(doc(usersDB, user.email), {...user, isAuthorized: true}, {merge: true});
		return {error: false, }
	} catch (e) {
		return { error: true, code: e.code ? e.code : 0, message: e.message };
	}
}
