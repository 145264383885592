import React from "react";
import classNames from "classnames";
import { SectionTilesProps } from "../../utils/SectionProps";
import SectionHeader from "./partials/SectionHeader";
import Image from "../elements/Image";

const propTypes = {
	...SectionTilesProps.types,
};

const defaultProps = {
	...SectionTilesProps.defaults,
};

class FeaturesTiles extends React.Component {
	render() {
		const {
			className,
			topOuterDivider,
			bottomOuterDivider,
			topDivider,
			bottomDivider,
			hasBgColor,
			invertColor,
			pushLeft,
			...props
		} = this.props;

		const outerClasses = classNames(
			"features-tiles section center-content",
			topOuterDivider && "has-top-divider",
			bottomOuterDivider && "has-bottom-divider",
			hasBgColor && "has-bg-color",
			invertColor && "invert-color",
			className
		);

		const innerClasses = classNames(
			"features-tiles-inner section-inner",
			topDivider && "has-top-divider",
			bottomDivider && "has-bottom-divider"
		);

		const tilesClasses = classNames("tiles-wrap", pushLeft && "push-left");

		const sectionHeader = {
			title: "",
			paragraph: "",
		};

		return (
			<section {...props} className={outerClasses}>
				<div className="container">
					<div className={innerClasses}>
						<SectionHeader data={sectionHeader} className="center-content" />
						<div className={tilesClasses}>
							<div className="tiles-item reveal-scale-up">
								<div className="tiles-item-inner">
									<div className="features-tiles-item-header">
										<div className="features-tiles-item-image mb-16">
											<Image
												src={require("./../../assets/images/feature-tile-icon-01.svg")}
												alt="Features tile icon 01"
												width={80}
												height={80}
											/>
										</div>
									</div>
									<div className="features-tiles-item-content">
										<h4 className="mt-0 mb-8">User friendly</h4>
										<p className="m-0 text-sm">
											Easy to use UI and intuitive platform for anyone to use.
										</p>
									</div>
								</div>
							</div>

							<div
								className="tiles-item reveal-scale-up"
								data-reveal-delay="200"
							>
								<div className="tiles-item-inner">
									<div className="features-tiles-item-header">
										<div className="features-tiles-item-image mb-16">
											<Image
												src={require("./../../assets/images/feature-tile-icon-02.svg")}
												alt="Features tile icon 02"
												width={80}
												height={80}
											/>
										</div>
									</div>
									<div className="features-tiles-item-content">
										<h4 className="mt-0 mb-8">All in one</h4>
										<p className="m-0 text-sm">
											An all-in-one platform to manage everything from invites
											to staff.
										</p>
									</div>
								</div>
							</div>

							<div
								className="tiles-item reveal-scale-up"
								data-reveal-delay="400"
							>
								<div className="tiles-item-inner">
									<div className="features-tiles-item-header">
										<div className="features-tiles-item-image mb-16">
											<Image
												src={require("./../../assets/images/feature-tile-icon-03.svg")}
												alt="Features tile icon 03"
												width={80}
												height={80}
											/>
										</div>
									</div>
									<div className="features-tiles-item-content">
										<h4 className="mt-0 mb-8">Suite of Tools</h4>
										<p className="m-0 text-sm">
											A suite of tools to help you throw the best event you can.
										</p>
									</div>
								</div>
							</div>

							<div className="tiles-item reveal-scale-up">
								<div className="tiles-item-inner">
									<div className="features-tiles-item-header">
										<div className="features-tiles-item-image mb-16">
											<Image
												src={require("./../../assets/images/feature-tile-icon-04.svg")}
												alt="Features tile icon 04"
												width={80}
												height={80}
											/>
										</div>
									</div>
									<div className="features-tiles-item-content">
										<h4 className="mt-0 mb-8">Create Events</h4>
										<p className="m-0 text-sm">
											Create events when you plan on throwing a party, and have
											all your information in one place.
										</p>
									</div>
								</div>
							</div>

							<div
								className="tiles-item reveal-scale-up"
								data-reveal-delay="200"
							>
								<div className="tiles-item-inner">
									<div className="features-tiles-item-header">
										<div className="features-tiles-item-image mb-16">
											<Image
												src={require("./../../assets/images/feature-tile-icon-05.svg")}
												alt="Features tile icon 05"
												width={80}
												height={80}
											/>
										</div>
									</div>
									<div className="features-tiles-item-content">
										<h4 className="mt-0 mb-8">Send invites to other houses</h4>
										<p className="m-0 text-sm">
											Invite people from other houses to come!
										</p>
									</div>
								</div>
							</div>

							<div
								className="tiles-item reveal-scale-up"
								data-reveal-delay="400"
							>
								<div className="tiles-item-inner">
									<div className="features-tiles-item-header">
										<div className="features-tiles-item-image mb-16">
											<Image
												src={require("./../../assets/images/feature-tile-icon-06.svg")}
												alt="Features tile icon 06"
												width={80}
												height={80}
											/>
										</div>
									</div>
									<div className="features-tiles-item-content">
										<h4 className="mt-0 mb-8">Send reminders</h4>
										<p className="m-0 text-sm">
											Send reminders for your event and staff!
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
