import React, { useContext, useReducer } from "react";
import reducer from "./reducer";
import {
	createEvent,
	updateEvent,
	getAllHousesForSchoolOfUser,
	getEventByIDForUser,
	getEventsForUser,
	uploadMemberInfo,
	logout,
	login,
	resetPasswordEmail,
	handleResetPassword,
	handleVerifyEmail,
	getUsersDB,
	getBlackballListForHouse,
	getLiveUpdatesForEvent,
	checkInUser,
	checkInUserForm,
	checkOut,
	uncheckOut,
	closeEvent,
	markCSVExport,
	admin_getEvents,
	admin_addAdminUser,
	admin_addAuthorizedUser,
	admin_getSchools,
} from "../utils/firebase";
import {
	GET_HOUSES_BEGIN,
	GET_HOUSES_END,
	GET_HOUSES_ERROR,
	CREATE_EVENT_BEGIN,
	CREATE_EVENT_END,
	CREATE_EVENT_ERROR,
	UPDATE_EVENT_BEGIN,
	UPDATE_EVENT_END,
	UPDATE_EVENT_ERROR,
	GET_EVENTS_BEGIN,
	GET_EVENTS_END,
	GET_EVENTS_ERROR,
	GET_EVENT_BEGIN,
	GET_EVENT_END,
	GET_EVENT_ERROR,
	CHANGE_VALUE,
	CLEAR_VALUES,
	POPULATE_EVENT,
	UPLOAD_MEMBERS_BEGIN,
	UPLOAD_MEMBERS_END,
	UPLOAD_MEMBERS_ERROR,
	LOGOUT_BEGIN,
	LOGOUT_END,
	LOGOUT_ERROR,
	LOGIN_BEGIN,
	LOGIN_END,
	LOGIN_ERROR,
	FILL_DEFAULT_EVENT,
	SEND_RESET_PASSWORD_EMAIL_BEGIN,
	SEND_RESET_PASSWORD_EMAIL_END,
	SEND_RESET_PASSWORD_EMAIL_ERROR,
	RESET_PASSWORD_BEGIN,
	RESET_PASSWORD_END,
	RESET_PASSWORD_ERROR,
	VERIFY_EMAIL_BEGIN,
	VERIFY_EMAIL_END,
	VERIFY_EMAIL_ERROR,
	CLEAR_EVENT,
	GET_MEMBERS_BEGIN,
	GET_MEMBERS_END,
	GET_MEMBERS_ERROR,
	GET_LIVE_EVENT,
	ADMIN_GET_EVENTS_BEGIN,
	ADMIN_GET_EVENTS_ERROR,
	ADMIN_GET_EVENTS_END,
	ADMIN_CREATE_BEGIN,
	ADMIN_CREATE_END,
	ADMIN_CREATE_ERROR,
	ADMIN_GET_SCHOOLS_BEGIN,
	ADMIN_GET_SCHOOLS_END,
	ADMIN_GET_SCHOOLS_ERROR,
	ADMIN_CREATE_AUTH_USER_BEGIN,
	ADMIN_CREATE_AUTH_USER_END,
	ADMIN_CREATE_AUTH_USER_ERROR,
} from "./actions";
import { connectFirestoreEmulator } from "firebase/firestore";

const user = localStorage.getItem("user");

export const initialState = {
	houses: [],
	user: user ? JSON.parse(user) : null,
	role: "",
	errorMessage: "",
	successMessage: "",
	isLoading: false,
	obj: [],
	event: {},
	eventName: "",
	eventLocation: "",
	eventDescription: "",
	startTime: "",
	endTime: "",
	housesList: [],
	ticketPrice: 0,
	date: "",
	eventStaff: [],
	soberDrivers: [],
	allowGuests: false,
	maxGuestCount: 0,
	eventBlackball: [],
	members: [],
	liveEvent: {},
	isSelfService: false,
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const setErrorMessage = async (error) => {
		dispatch({
			type: CREATE_EVENT_ERROR,
			payload: {
				message: error,
			},
		});
	};

	const handleChange = (name, value) => {
		dispatch({
			type: CHANGE_VALUE,
			payload: { name, value },
		});
	};
	const fillDefaultEvent = () => {
		dispatch({
			type: FILL_DEFAULT_EVENT
		})
	}

	const clearEventValues = async () => {
		const result = await getBlackballListForHouse(state.user.greekHouse.realName, state.user.greekHouse.associatedSchool)
		if (result.error) {
			dispatch({
				type: CREATE_EVENT_ERROR,
				payload: {
					message: result.error,
				},
			});
		} else {
			dispatch({
				type: CLEAR_EVENT,
				payload: {
					blackball: result.blackball ? result.blackball : []
				}
			})
		}
		
	}

	const getLiveEvent = async (id) => {
		const result = await getLiveUpdatesForEvent(id);
		if (result.error) {
			dispatch({
				type: CREATE_EVENT_ERROR,
				payload: {
					message: result.error,
				},
			});
		} else {
			dispatch({
				type: GET_LIVE_EVENT,
				payload: {
					liveEvent: result.eventSubscribe,
				}
			})
		}
	}

	
	const clearValues = () => {};
	const getAllHousesForSchool = async () => {
		dispatch({
			type: GET_HOUSES_BEGIN,
		});
		const result = await getAllHousesForSchoolOfUser();
		if (result.error === false) {
			const housesNames = result.houses.map((obj) => {
				const data = obj
				return {
					value: data,
					label: data,
				};
			});
			const housesNamesFiltered = housesNames.filter(obj => obj.label !== state.user.greekHouse.realName)
			dispatch({
				type: GET_HOUSES_END,
				payload: { houses: housesNamesFiltered },
			});
		} else {
			dispatch({
				type: GET_HOUSES_ERROR,
				payload: {
					message: result.message,
				},
			});
		}
	};
	const createEventForUser = async (eventData) => {
		dispatch({
			type: CREATE_EVENT_BEGIN,
		});
		const result = await createEvent(eventData);
		if (result.error === false) {
			dispatch({
				type: CREATE_EVENT_END,
				payload: {
					obj: result.event,
				},
			});
		} else {
			dispatch({
				type: CREATE_EVENT_ERROR,
				payload: {
					message: result.message,
				},
			});
		}
	};

	const updateEventForUser = async (eventData) => {
		dispatch({
			type: UPDATE_EVENT_BEGIN,
		});
		const result = await updateEvent(eventData, state.event);
		if (result.error === false) {
			dispatch({
				type: UPDATE_EVENT_END,
				payload: {
					obj: result.event,
				},
			});
		} else {
			dispatch({
				type: UPDATE_EVENT_ERROR,
				payload: {
					message: result.message,
				},
			});
		}
	};

	const getAllEvents = async () => {
		dispatch({
			type: GET_EVENTS_BEGIN,
		});
		const result = await getEventsForUser();
		if (result.error === false) {
			dispatch({
				type: GET_EVENTS_END,
				payload: { obj: result.events },
			});
		} else {
			dispatch({
				type: GET_EVENTS_ERROR,
				payload: {
					message: result.message,
				},
			});
		}
	};

	const getAllMembers = async () => {
		dispatch({
			type: GET_MEMBERS_BEGIN,
		})
		const result = await getUsersDB();
		if (result.error === false) {
			dispatch({
				type: GET_MEMBERS_END,
				payload: { members: result.members, schools: result.schools },
			});
		} else {
			dispatch({
				type: GET_MEMBERS_ERROR,
				payload: {
					message: result.message,
				},
			});
		}
	}

	const getEventByID = async (id) => {
		dispatch({
			type: GET_EVENT_BEGIN,
		});
		const result = await getEventByIDForUser(id);
		if (result.error === false) {
			dispatch({
				type: GET_EVENT_END,
				payload: { event: result.event },
			});
			populateEvent();
		} else {
			dispatch({
				type: GET_EVENT_ERROR,
				payload: {
					message: result.message,
				},
			});
		}
	};

	const populateEvent = async () => {
		dispatch({
			type: POPULATE_EVENT,
		});
	};

	const uploadMembers = async (data) => {
		dispatch({
			type: UPLOAD_MEMBERS_BEGIN
		})
		const result = await uploadMemberInfo(data);
		if (result.error === false) {
			dispatch({
				type: UPLOAD_MEMBERS_END,
			});
			handleChange("successMessage", "Successfully uploaded!")
		} else {
			dispatch({
				type: UPLOAD_MEMBERS_ERROR,
				payload: {
					message: result.message,
				},
			});
		}
	}

	const logoutUser = async () => {
		dispatch({
			type: LOGOUT_BEGIN
		})
		const result = await logout()
		if(result.error === false) {
			dispatch({type: LOGOUT_END})
		} else {
			dispatch({
				type: LOGOUT_ERROR,
				payload: {
					message: result.message,
				},
			});
		}
	}
	
	const loginUser = async (data) => {
		dispatch({
			type: LOGIN_BEGIN
		})
		const result = await login(data.username, data.password)
		if(result.error === false) {
			dispatch({type: LOGIN_END, payload: {user: result.user}})

		} else {
			dispatch({
				type: LOGIN_ERROR,
				payload: {
					message: result.message,
				},
			})
		}

	}

	const sendResetEmail = async (email) => {
		dispatch({
			type: SEND_RESET_PASSWORD_EMAIL_BEGIN
		})
		const result = await resetPasswordEmail(email)
		if(result.error === false) {
			dispatch({type: SEND_RESET_PASSWORD_EMAIL_END, payload: {email: email}})

		} else {
			dispatch({
				type: SEND_RESET_PASSWORD_EMAIL_ERROR,
				payload: {
					message: result.message,
				},
			})
		}
	}

	const resetPassword = async (newPassword, actionCode, lang) => {
		dispatch({
			type: RESET_PASSWORD_BEGIN
		})
		const result = await handleResetPassword(actionCode,"", lang, newPassword)
		if(result.error === false) {
			dispatch({type: RESET_PASSWORD_END, })

		} else {
			dispatch({
				type: RESET_PASSWORD_ERROR,
				payload: {
					message: result.message,
				},
			})
		}
	}

	const verifyEmail = async (actionCode, continueUrl,  lang) => {
		dispatch({
			type: VERIFY_EMAIL_BEGIN
		})
		const result = await handleVerifyEmail(actionCode, continueUrl, lang)
		if(result.error === false) {
			dispatch({type: VERIFY_EMAIL_END, })

		} else {
			dispatch({
				type: VERIFY_EMAIL_ERROR,
				payload: {
					message: result.message,
				},
			})
		}
	}

	const checkInAtEvent = async (eventId, email, drinks) => {
		dispatch({
			type: VERIFY_EMAIL_BEGIN
		})
		const result = await checkInUser({eventId, email, drinks})

		if(result.error === false) {
			dispatch({type: VERIFY_EMAIL_END })

		} else {
			dispatch({
				type: VERIFY_EMAIL_ERROR,
				payload: {
					message: result.message,
				},
			})
		}
	}

	const checkInAtEventForm = async (eventId, email, drinks, userData) => {
		dispatch({
			type: VERIFY_EMAIL_BEGIN
		})

		const result = await checkInUserForm({eventId, email, drinks, userData})

		if(result.error === false) {
			dispatch({type: VERIFY_EMAIL_END })

		} else {
			dispatch({
				type: VERIFY_EMAIL_ERROR,
				payload: {
					message: result.message,
				},
			})
		}
	}

	const checkOutAtEvent = async ({eventId, userData}) => {
		dispatch({
			type: VERIFY_EMAIL_BEGIN
		})

		const result = await checkOut({eventId, userData});

		if(result.error === false) {
			dispatch({type: VERIFY_EMAIL_END })

		} else {
			dispatch({
				type: VERIFY_EMAIL_ERROR,
				payload: {
					message: result.message,
				},
			})
		}
	}

	const uncheckOutAtEvent = async ({eventId, userData}) => {
		dispatch({
			type: VERIFY_EMAIL_BEGIN
		})

		const result = await uncheckOut({eventId, userData});

		if(result.error === false) {
			dispatch({type: VERIFY_EMAIL_END })

		} else {
			dispatch({
				type: VERIFY_EMAIL_ERROR,
				payload: {
					message: result.message,
				},
			})
		}
	}

	const lockEvent = async ({eventId}) => {
		dispatch({
			type: VERIFY_EMAIL_BEGIN
		})

		const result = await closeEvent({eventId});

		if(result.error === false) {
			dispatch({type: VERIFY_EMAIL_END })

		} else {
			dispatch({
				type: VERIFY_EMAIL_ERROR,
				payload: {
					message: result.message,
				},
			})
		}
	}


	const markCSVExportTime = async ({eventId, csvType}) => {
		dispatch({
			type: VERIFY_EMAIL_BEGIN
		})

		const result = await markCSVExport({eventId, csvType});

		if(result.error === false) {
			dispatch({type: VERIFY_EMAIL_END })

		} else {
			dispatch({
				type: VERIFY_EMAIL_ERROR,
				payload: {
					message: result.message,
				},
			})
		}
	}

	//Admin Functions
	const admin_getAllEvents = async () => {
		dispatch({
			type: ADMIN_GET_EVENTS_BEGIN
		})
		const result = await admin_getEvents();

		if(result.error === false) {
			dispatch({type: ADMIN_GET_EVENTS_END, payload: { events: result.events } })

		} else {
			dispatch({
				type: ADMIN_GET_EVENTS_ERROR,
				payload: {
					message: result.message,
				},
			})
		}
	}

	const admin_createAdmin = async (user) => {
		dispatch({
			type: ADMIN_CREATE_BEGIN
		})
		const result = await admin_addAdminUser(user);
		if(result.error === false) {
			dispatch({type: ADMIN_CREATE_END })

		} else {
			dispatch({
				type: ADMIN_CREATE_ERROR,
				payload: {
					message: result.message,
				},
			})
		}
	}

	const admin_createAuthUser = async (user) => {
		dispatch({
			type: ADMIN_CREATE_AUTH_USER_BEGIN
		})
		const result = await admin_addAuthorizedUser(user);
		if(result.error === false) {
			dispatch({type: ADMIN_CREATE_AUTH_USER_END })

		} else {
			dispatch({
				type: ADMIN_CREATE_AUTH_USER_ERROR,
				payload: {
					message: result.message,
				},
			})
		}
	}

	const admin_getSchoolsContext = async () => {
		dispatch({
			type: ADMIN_GET_SCHOOLS_BEGIN
		})

		const result = await admin_getSchools();
		
		if(result.error === false) {
			dispatch({type: ADMIN_GET_SCHOOLS_END, payload: {schools: result.schools }})

		} else {
			dispatch({
				type: ADMIN_GET_SCHOOLS_ERROR,
				payload: {
					message: result.message,
				},
			})
		}

		
	}
	return (
		<AppContext.Provider
			value={{
				...state,
				getAllHousesForSchool,
				createEventForUser,
				updateEventForUser,
				setErrorMessage,
				getAllEvents,
				getEventByID,
				handleChange,
				populateEvent,
				uploadMembers,
				logoutUser,
				loginUser,
				fillDefaultEvent,
				sendResetEmail,
				resetPassword,
				verifyEmail,
				clearEventValues,
				getAllMembers,
				getLiveEvent,
				checkInAtEvent,
				checkInAtEventForm,
				checkOutAtEvent,
				uncheckOutAtEvent,
				lockEvent,
				markCSVExportTime,
				admin_getAllEvents,
				admin_createAdmin,
				admin_getSchoolsContext,
				admin_createAuthUser,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export const useAppContext = () => {
	return useContext(AppContext);
};

export { AppProvider };
