import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link, Redirect } from "react-router-dom";
import Logo from "./partials/Logo";
import { withUser } from "./withUser";


const propTypes = {
	active: PropTypes.bool,
	navPosition: PropTypes.string,
	hideNav: PropTypes.bool,
	hideSignin: PropTypes.bool,
	bottomOuterDivider: PropTypes.bool,
	bottomDivider: PropTypes.bool,

};

const defaultProps = {
	active: false,
	navPosition: "",
	hideNav: false,
	hideSignin: false,
	bottomOuterDivider: false,
	bottomDivider: false,
};

class Header extends React.Component {
	state = {
		isActive: false,
		navigate: false,
	};

	nav = React.createRef();
	hamburger = React.createRef();

	componentDidMount() {
		this.props.active && this.openMenu();
		document.addEventListener("keydown", this.keyPress);
		document.addEventListener("click", this.clickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.keyPress);
		document.addEventListener("click", this.clickOutside);
		this.closeMenu();
	}

	openMenu = () => {
		document.body.classList.add("off-nav-is-active");
		this.nav.current.style.maxHeight = this.nav.current.scrollHeight + "px";
		this.setState({ isActive: true });
	};

	closeMenu = () => {
		document.body.classList.remove("off-nav-is-active");
		this.nav.current && (this.nav.current.style.maxHeight = null);
		this.setState({ isActive: false });
	};

	keyPress = (e) => {
		this.state.isActive && e.keyCode === 27 && this.closeMenu();
	};

	clickOutside = (e) => {
		if (!this.nav.current) return;
		if (
			!this.state.isActive ||
			this.nav.current.contains(e.target) ||
			e.target === this.hamburger.current
		)
			return;
		this.closeMenu();
	};

	onLogout = (e) => {
		e.stopPropagation()
		try {
			const logoutUser = this.props.logoutuser
			logoutUser()
			this.setState({...this.state, navigate: true})
		} catch (e) {
			console.log(e.message)
		}
	}

	render() {
		const user = this.props.user;

		const {
			className,
			active,
			navPosition,
			hideNav,
			bottomOuterDivider,
			bottomDivider,
			...props
		} = this.props;

		const classes = classNames(
			"site-header",
			bottomOuterDivider && "has-bottom-divider",
			className
		);

		

		return (
			<header {...props} className={classes}>
				<div className="container">
					<div
						className={classNames(
							"site-header-inner",
							bottomDivider && "has-bottom-divider"
						)}
					>
						{this.state.navigate && <Redirect to="/login" replace={true} /> }
						<Logo />
						

						{!hideNav && (
							<React.Fragment>
								<button
									ref={this.hamburger}
									className="header-nav-toggle"
									onClick={this.state.isActive ? this.closeMenu : this.openMenu}
								>
									<span className="screen-reader">Menu</span>
									<span className="hamburger">
										<span className="hamburger-inner"></span>
									</span>
								</button>
								<nav
									ref={this.nav}
									className={classNames(
										"header-nav",
										this.state.isActive && "is-active"
									)}
								>
									<div className="header-nav-inner">
										
										<ul
											className={classNames(
												"list-reset text-xxs",
												navPosition && `header-nav-${navPosition}`
											)}
										>
										</ul>
										{!user ? (
											<ul className="list-reset header-nav-right">
												<li>
													<Link
														to="/login/"
														className="button button-primary button-wide-mobile button-sm"
														onClick={this.closeMenu}
													>
														Log In
													</Link>
												</li>
											</ul>
										) : (
											<ul className="list-reset header-nav-right">
												
												<li>
													<Link
														
														className="button button-secondary button-wide-mobile button-sm"
														to="/events"
													>
														Events
													</Link>
												</li>
												{user && user.isAuthorized && 
												<li>
													<Link
														
														className="button button-secondary button-wide-mobile button-sm"
														to="/member/upload"
													>
														Upload Members
													</Link>
												</li>
												}
												{user && user.isAuthorized && 
												<li>
													<Link
														
														className="button button-secondary button-wide-mobile button-sm"
														to="/member/list"
													>
														Member Lookup
													</Link>
												</li>
												}
												{user && user.admin && 
												<li>
													<Link
														
														className="button button-secondary button-wide-mobile button-sm"
														to="/admin/events"
													>
														Admin View
													</Link>
												</li>
												}
												<li>
													<Link
														to="/login"
														className="button button-primary button-wide-mobile button-sm "
														onClick={this.onLogout}
													>
														Log Out
													</Link>
												</li>
												
											</ul>
										)}
									</div>
								</nav>
							</React.Fragment>
						)}
					</div>
				</div>
			</header>
		);
	}
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default withUser(Header);
