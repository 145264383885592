import React, { useEffect, useState } from "react";
import Input from "../../components/elements/Input";
import Button from "../../components/elements/Button";
import Switch from "../../components/elements/Switch";
import Select from "react-select";
import { useAppContext } from "../../context/appContext";
import { Redirect, useHistory, useParams } from "react-router-dom"
import Modal from "../../components/elements/Modal";

const initState = {redirect: false}
function EventDetailView() {
	const { id } = useParams();
	const [values, setValues] = useState(initState);
	const [modalOpen, setModalOpen] = useState(false)
	const {
		houses,
		getAllHousesForSchool,
		updateEventForUser,
		errorMessage,
		setErrorMessage,
		successMessage,
		getEventByID,
		handleChange,
		eventName,
		eventDescription,
		eventLocation,
		eventStaff,
		startTime,
		date,
		endTime,
		ticketPrice,
		housesList,
		isLoading,
		allowGuests,
		maxGuestCount,
		isBYOB,
		user,
		eventBlackball,
	} = useAppContext();
	const roles = ["Sober Driver", "Roamer", "Check in Staff"]

	const history = useHistory();
	


	const handleInputChange = (e) => {
        
		if(e.target.name === "isBYOB" || e.target.name === "allowGuests") {
            if(e.target.value == null){
                handleChange(e.target.name, false);
            } else {
                handleChange(e.target.name, e.target.checked);
            }
        } else {
            handleChange(e.target.name, e.target.value);
        }
	};

	
	const handleChangeForStaff = (e, index) => {
		let eventStaffNew = eventStaff;
		eventStaffNew[index][e.target.name] = e.target.value;
		handleChange("eventStaff", eventStaffNew);
	};

	const handleChangeForBlackball = (e, index) => {
		let eventBlackballNew = eventBlackball;
		eventBlackballNew[index][e.target.name] = e.target.value;
		handleChange("eventBlackball", eventBlackballNew);
	};

	const handleChangeForHouses = (housesList) => {
		handleChange("housesList", housesList);
	};
	useEffect(() => {
		(async () => {
			getAllHousesForSchool();
			getEventByID(id);
		})();
	}, []);

	

	const addBlackball = (e) => {
		e.preventDefault();
		var eventBlackballNew = eventBlackball;
		eventBlackballNew.push({
			firstName: "",
			lastName: "",
			email: "",
		});
		handleChange("eventBlackball", eventBlackballNew);
	};

	const delBlackball = (e, index) => {
		var eventBlackballNew = eventBlackball;
		eventBlackballNew.splice(index, 1);
		handleChange("eventBlackball", eventBlackballNew);
	};

	const addStaffRow = (e) => {
		e.preventDefault();
		var eventStaffNew = eventStaff;
		eventStaffNew.push({
			role: "Sober Driver",
			firstName: "",
			lastName: "",
			phoneNumber: "",
            email: "",
		});
		handleChange("eventStaff", eventStaffNew);
	};
	const delStaffRow = (e, index) => {
		var eventStaffNew = eventStaff;
		eventStaffNew.splice(index, 1);
		handleChange("eventStaff", eventStaffNew);
	};

	const checkEventStaff = (eventStaff) => {
		for (let i = 0; i < eventStaff.length; i++) {
			const currStaff = eventStaff[i];
			if (
				currStaff.firstName === "" ||
				currStaff.lastName === "" ||
				currStaff.phoneNumber === "" ||
				currStaff.role === "" ||
                currStaff.email === ""
			) {
				setErrorMessage(
					"Please input all required fields for staff (Role, First Name, Last Name, Phone Number, Email) or delete the row."
				);
				return false;
			}
		}
	};

	const onSubmit = (e) => {
		e.preventDefault();

		const housesListAsList = Array.from(housesList);
		let housesListModified = []
		var modifiedBYOB = isBYOB;
        if(modifiedBYOB === undefined){
            modifiedBYOB = false;
        }
		for (let i = 0; i < housesListAsList.length; i++) {
			housesListModified.push(housesListAsList[i].value )
		}
		if (
			checkEventStaff(eventStaff) === false
		) {
			return;
		}
		if (
			!eventName ||
			!eventDescription ||
			!eventLocation ||
			!eventStaff ||
			!startTime ||
			!endTime ||
			!date
		) {
			setErrorMessage("Please input all values!");
			//setValues({...values, errorMessage: "Please input all information."})
		} else {
			setErrorMessage("");
			updateEventForUser({
				_id: id,
				eventName,
		        eventDescription,
		        eventLocation,
		        eventStaff,
				eventBlackball,
		        housesList: housesListModified,
			    startTime,
		        endTime,
	    		date,
                isBYOB: modifiedBYOB,
				allowGuests: allowGuests ? true : false,
				maxGuestCount: allowGuests && maxGuestCount > 0 ? maxGuestCount : -1,
			});
			
			if (errorMessage === "") {
				setTimeout(() => {history.push("/events")}, 3000)
				
			}
			
		}

	};
	return (
		<React.Fragment>
			<div className="container">
				<div className="mb-4 sm:mb-0 mt-6">
					<h3
						className="text-md md:text-xl text-slate-800 font-bold"
						style={{ paddingTop: 50 }}
					>
						View/Modify Event 🎉
					</h3>
				</div>
				<Modal show={modalOpen} handleClose={(e) => { e.preventDefault(); setModalOpen(false) }}>
					<h4>BYOB Change Confirmation</h4>
					<p>Are you sure you would like to change BYOB status? This will cause beverage data to potentially be lost if you decide to revert back.</p>
					<Button onClick={(ev) => {ev.preventDefault(); const e = {target: {name: "isBYOB", checked: isBYOB ? false : true}}; handleInputChange(e); setModalOpen(false); }}>Confirm</Button>
				</Modal>
				{isLoading ? (
					<div className="hero-inner section-inner center-content">
						<Button loading={isLoading}>Loading</Button>
					</div>
				) : (
					<div
						className="container has-top-divider"
						style={{ paddingBottom: 0 }}
					>
						<div className="container section-inner">
						<Input
								type="text"
								name="eventName"
								value={eventName}
								onChange={handleInputChange}
								label="Event Name"
							/>
							<Input
								type="textarea"
								name="eventDescription"
								value={eventDescription}
								onChange={handleInputChange}
								label="Event Description"
							/>
							<Input
								type="text"
								name="eventLocation"
								value={eventLocation}
								onChange={handleInputChange}
								label="Event Location"
							/>
							<Input
								type="date"
								name="date"
								value={date}
								onChange={handleInputChange}
								label="Event Date"
							/>
							<Input
								type="time"
								name="startTime"
								value={startTime}
								onChange={handleInputChange}
								label="Start Time"
							/>
							<Input
								type="time"
								name="endTime"
								value={endTime}
								onChange={handleInputChange}
								label="End Time"
							/>
                            <Switch
                                name="isBYOB"
                                checked={isBYOB}
                                leftLabel="Is the event BYOB?"
                                onChange={(e) => {if(isBYOB) {setModalOpen(true)} else {handleInputChange(e)}}}
                            />
							<Switch
                                name="allowGuests"
                                checked={allowGuests}
                                leftLabel={"Do you want to allow guests from " + user.greekHouse.realName + " to invite others?"}
                                onChange={handleInputChange}
                            />
							<div />
							{allowGuests && <Input
								type="number"
								name="maxGuestCount"
								value={maxGuestCount}
								onChange={handleInputChange}
								label="Guest Limit"
							/>}
						</div>
						<div className="hero-inner">
                            <h4 className="text-md md:text-xl text-slate-800 font-bold" style={{ paddingBottom: 10 }}>
								Invite Houses
							</h4>
                            <h4 className="text-sm md:text-m text-slate-800 font-bold" style={{ paddingBottom: 10 }}>
                                If houses do not show up in the drop down below, refresh the page and try again.
                            </h4>
						</div>
						<div className="center-content row column">
							<Select
								options={houses}
								isMulti
								name="houses"
								value={housesList}
								onChange={handleChangeForHouses}
							/>
						</div>

						{
							<div>
								<h4 className="text-md md:text-xl text-slate-800 font-bold" style={{ padding: 10 }}>
									Event Staff
								</h4>
								<table>
									<thead className="thead-dark">
										<tr>
										<th>Role</th>
											<th>First Name</th>
											<th>Last Name</th>
											<th>Phone Number</th>
                                            <th>Email Address</th>
										</tr>
									</thead>
									<tbody>
										{eventStaff.map((r, index) => (
											<tr key={index}>
												<td>
													<select placeholder="Please Select A Role" name="role" value={r.role} onChange={(e) => {
															handleChangeForStaff(e, index);
														}}>
														{roles.map((role, i) => {
															return <option key={i} value={role}>{role}</option>
														})}
													</select>
												</td>
												<td>
													<Input
														type="text"
														name="firstName"
														placeholder="First Name"
														value={r.firstName}
														onChange={(e) => {
															handleChangeForStaff(e, index);
														}}
													/>
												</td>
												<td>
													<Input
														type="text"
														name="lastName"
														placeholder="Last Name"
														value={r.lastName}
														onChange={(e) => {
															handleChangeForStaff(e, index);
														}}
													/>
												</td>
												<td>
													<Input
														type="tel"
														name="phoneNumber"
														placeholder="Phone Number"
														value={r.phoneNumber}
														onChange={(e) => {
															handleChangeForStaff(e, index);
														}}
													/>
												</td>

                                                <td>
                                                    <Input
                                                        type="email"
                                                        name="email"
                                                        placeholder="Email Address"
                                                        value={r.email}
                                                        onChange={(e) => {
                                                            handleChangeForStaff(e, index);
                                                        }}
                                                    />
                                                </td>
												<td>
													<Button
														className="mt-15"
														onClick={(e) => {
															delStaffRow(e, index);
														}}
														size="sm"
													>
														Delete
													</Button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
								<Button className="mt-15" onClick={addStaffRow} size="sm">
									Add Staff Member
								</Button>
							</div>
						}
						{
							<div>
								<h4 className="text-md md:text-xl text-slate-800 font-bold" style={{ padding: 10 }}>
									Event Blackball
								</h4>
								<h4 className="text-sm md:text-md text-slate-800 font-bold" style={{ padding: 10 }}>
									You can look up any user's details on the Member Lookup page.
								</h4>
								<table>
									<thead className="thead-dark">
										<tr>
											<th>First Name</th>
											<th>Last Name</th>
											<th>Email Address</th>
										</tr>
									</thead>
									<tbody>
										{eventBlackball.map((r, index) => (
											<tr key={index}>
												<td>
													<Input
														type="text"
														name="firstName"
														placeholder="First Name"
														value={r.firstName}
														onChange={(e) => {
															handleChangeForBlackball(e, index);
														}}
													/>
												</td>
												<td>
													<Input
														type="text"
														name="lastName"
														placeholder="Last Name"
														value={r.lastName}
														onChange={(e) => {
															handleChangeForBlackball(e, index);
														}}
													/>
												</td>

												<td>
													<Input
														type="email"
														name="email"
														placeholder="Email Address"
														value={r.email}
														onChange={(e) => {
															handleChangeForBlackball(e, index);
														}}
													/>
												</td>
												<td>
													<Button
														className="mt-15"
														onClick={(e) => {
															delBlackball(e, index);
														}}
														size="sm"
													>
														Delete
													</Button>
												</td>
											</tr>
										))}
									</tbody>
								</table>
								<Button className="mt-15" onClick={addBlackball} size="sm">
									Add Person to Blackball List
								</Button>
							</div>
						}
						<div className="center-content" style={{ padding: 15 }}>
							<Button className="mt-15" onClick={onSubmit}>
								Submit Modifications
							</Button>
						</div>
						{errorMessage.length > 0 ? (
							<div
								className="mt-24"
								style={{ background: "white", borderRadius: 15 }}
							>
								<p
									style={
										errorMessage.length !== 0
											? {
													padding: 5,
													color: "red",
													fontSize: 15,
													textAlign: "center",
											  }
											: {}
									}
								>
									{errorMessage}
								</p>
							</div>
						) : (
							<div />
						)}
						{successMessage.length > 0 ? (
							<div
								className="mt-24"
								style={{ background: "white", borderRadius: 15 }}
							>
								<p
									style={
										successMessage.length !== 0
											? {
													padding: 5,
													color: "green",
													fontSize: 15,
													textAlign: "center",
											  }
											: {}
									}
								>
									{successMessage}
								</p>
							</div>
						) : (
							<div />
						)}
					</div>
				)}
			</div>
		</React.Fragment>
	);
}

export default EventDetailView;
