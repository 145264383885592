import { initialState } from "./appContext";

import {
	CREATE_EVENT_BEGIN,
	CREATE_EVENT_END,
	CREATE_EVENT_ERROR,
	UPDATE_EVENT_BEGIN,
	UPDATE_EVENT_END,
	UPDATE_EVENT_ERROR,
	GET_HOUSES_BEGIN,
	GET_HOUSES_END,
	GET_HOUSES_ERROR,
	GET_EVENTS_BEGIN,
	GET_EVENTS_END,
	GET_EVENTS_ERROR,
	GET_EVENT_BEGIN,
	GET_EVENT_END,
	GET_EVENT_ERROR,
	POPULATE_EVENT,
	CHANGE_VALUE,
	UPLOAD_MEMBERS_BEGIN,
	UPLOAD_MEMBERS_END,
	UPLOAD_MEMBERS_ERROR,
	LOGOUT_BEGIN,
	LOGOUT_END,
	LOGOUT_ERROR,
	LOGIN_BEGIN,
	LOGIN_END,
	LOGIN_ERROR,
	FILL_DEFAULT_EVENT,
	SEND_RESET_PASSWORD_EMAIL_BEGIN,
	SEND_RESET_PASSWORD_EMAIL_END,
	SEND_RESET_PASSWORD_EMAIL_ERROR,
	RESET_PASSWORD_BEGIN,
	RESET_PASSWORD_END,
	RESET_PASSWORD_ERROR,
	VERIFY_EMAIL_BEGIN,
	VERIFY_EMAIL_END,
	VERIFY_EMAIL_ERROR,
	CLEAR_EVENT,
	GET_MEMBERS_BEGIN,
	GET_MEMBERS_END,
	GET_MEMBERS_ERROR,
	GET_LIVE_EVENT,
	ADMIN_GET_EVENTS_BEGIN,
	ADMIN_GET_EVENTS_END,
	ADMIN_GET_EVENTS_ERROR,
	ADMIN_CREATE_BEGIN,
	ADMIN_CREATE_END,
	ADMIN_CREATE_ERROR,
	ADMIN_GET_SCHOOLS_BEGIN,
	ADMIN_GET_SCHOOLS_END,
	ADMIN_GET_SCHOOLS_ERROR,
	ADMIN_CREATE_AUTH_USER_BEGIN,
	ADMIN_CREATE_AUTH_USER_END,
	ADMIN_CREATE_AUTH_USER_ERROR
} from "./actions";

const reducer = (state, action) => {
	switch (action.type) {
		case CHANGE_VALUE:
			return {
				...state,
				[action.payload.name]: action.payload.value,
			};
		case POPULATE_EVENT:
			const housesNames = state.event.housesList.filter(obj => obj !== state.user.greekHouse.realName).map((obj) => {
				return {
					value: obj,
					label: obj,
				};
			});
			const dateArr = state.event.date.split("-")
			return {
				...state,
				eventName: state.event.eventName,
				eventLocation: state.event.eventLocation,
				eventDescription: state.event.eventDescription,
				startTime: state.event.startTime,
				endTime: state.event.endTime,
				housesList: housesNames,
				ticketPrice: state.event.ticketPrice,
				date: dateArr[2] + "-" + dateArr[0] + "-" + dateArr[1],
				eventStaff: state.event.eventStaff ? [...state.event.eventStaff] : [],
				isBYOB: state.event.isBYOB,
				allowGuests: state.event.allowGuests,
				maxGuestCount: state.event.maxGuestCount,
				errorMessage: "",
				eventBlackball: state.event.eventBlackball ? state.event.eventBlackball : [],
			};
		case CLEAR_EVENT:
			return {
				...state,
				eventName: "",
				eventLocation: "",
				eventDescription: "",
				startTime: "",
				endTime: "",
				housesList: [],
				ticketPrice: 0,
				date: "",
				eventStaff: [],
				eventBlackball: action.payload.blackball,
				soberDrivers: [],
				isBYOB: false,
				allowGuests: false,
				maxGuestCount: 0,
				errorMessage: "",
			}
		case GET_LIVE_EVENT:
			return {
				...state,
				liveEvent: action.payload.liveEvent,
			}

		case GET_HOUSES_BEGIN:
			return {
				...state,
				isLoading: true,
			};
		case GET_HOUSES_END:

			return {
				...state,
				isLoading: false,
				houses: action.payload.houses,
			};
		case GET_HOUSES_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload.message,
			};
		case GET_MEMBERS_BEGIN:
			return { ...state, isLoading: true }
		case GET_MEMBERS_END:
			return {
				...state,
				isLoading: false,
				members: action.payload.members,
				schools: action.payload.schools,
			}
		case GET_MEMBERS_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload.message,
			};
		case CREATE_EVENT_BEGIN:
			return {
				...state,
				isLoading: true,
			};
		case CREATE_EVENT_END:
			return {
				...state,
				isLoading: false,
				createdEvent: action.payload.obj,
				successMessage: "Created new Event!",
			};
		case CREATE_EVENT_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload.message,
			};
		case UPDATE_EVENT_BEGIN:
			return {
				...state,
				isLoading: true,
			};
		case UPDATE_EVENT_END:
			return {
				...state,
				isLoading: false,
				event: action.payload.obj,
				successMessage: "Updated Event!",
			};
		case UPDATE_EVENT_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload.message,
			};
		case GET_EVENTS_BEGIN:
			return {
				...state,
				isLoading: true,
			};
		case GET_EVENTS_END:
			return {
				...state,
				isLoading: false,
				obj: action.payload.obj,
			};
		case GET_EVENTS_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload.message,
			};
		case ADMIN_GET_EVENTS_BEGIN:
			return {
				...state,
				isLoading: true,
			};
		case ADMIN_GET_EVENTS_END:
			return {
				...state,
				isLoading: false,
				events: action.payload.events,
			};
		case ADMIN_GET_EVENTS_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload.message,
			};
		case GET_EVENT_BEGIN:
			return {
				...state,
				isLoading: true,
			};
		case GET_EVENT_END:
			return {
				...state,
				isLoading: false,
				event: action.payload.event,
			};
		case GET_EVENT_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload.message,
			};
		case UPLOAD_MEMBERS_BEGIN:
			return {
				...state,
				isLoading: true,
			};
		case UPLOAD_MEMBERS_END:
			return {
				...state,
				isLoading: false,
			};
		case UPLOAD_MEMBERS_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload.message,
			};
		case LOGOUT_BEGIN:
			return {
				...state,
				isLoading: true,
			};
		case LOGOUT_END:
			return {
				...initialState,
				isLoading: false,
				user: null,
			}
		case LOGOUT_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload.message,
			};
		case LOGIN_BEGIN:
			return {
				...state,
				isLoading: true,
			}
		case LOGIN_END:
			return {
				...state,
				isLoading: false,
				user: action.payload.user,
				errorMessage: "",
			}
		case LOGIN_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload.message,
			}
		case SEND_RESET_PASSWORD_EMAIL_BEGIN:
			return {
				...state,
				errorMessage: "",
				successMessage: "",
				isLoading: true,
			}
		case SEND_RESET_PASSWORD_EMAIL_END:
			return {
				...state,
				successMessage: `Sent Password Reset Email to ${action.payload.email}!`,
				errorMessage: "",
				isLoading: false,
			}
		case SEND_RESET_PASSWORD_EMAIL_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload.message,
			}
		case RESET_PASSWORD_BEGIN:
			return {
				...state,
				errorMessage: "",
				successMessage: "",
				isLoading: true,
			}
		case RESET_PASSWORD_END:
			return {
				...state,
				successMessage: `Password successfully reset!`,
				errorMessage: "",
				isLoading: false,
			}
		case RESET_PASSWORD_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload.message,
				successMessage: "",
			}
		case VERIFY_EMAIL_BEGIN:
			return {
				...state,
				isLoading: true,
			}
		case VERIFY_EMAIL_END:
			return {
				...state,
				successMessage: `Email successfully verified!`,
				errorMessage: "",
				isLoading: false,
			}
		case VERIFY_EMAIL_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload.message,
				successMessage: "",
			}
		case ADMIN_CREATE_BEGIN:
			return {
				...state,
				isLoading: true,
			}
		case ADMIN_CREATE_END:
			return {
				...state,
				successMessage: `Admin user successfully created!`,
				errorMessage: "",
				isLoading: false,
			}
		case ADMIN_CREATE_ERROR:
			return {
				...state,
				successMessage: ``,
				errorMessage: action.payload.message,
				isLoading: false,
			}
		case ADMIN_GET_SCHOOLS_BEGIN:
			return {
				...state,
				isLoading: true,
			}
		case ADMIN_GET_SCHOOLS_END:
			return {
				...state,
				schools: action.payload.schools,
				isLoading: false,
			}
		case ADMIN_GET_SCHOOLS_ERROR:
			return {
				...state,
				isLoading: false,
				errorMessage: action.payload.message,
				successMessage: "",
			}
		case ADMIN_CREATE_AUTH_USER_BEGIN:
			return {
				...state,
				isLoading: true,
			}
		case ADMIN_CREATE_AUTH_USER_END:
			return {
				...state,
				successMessage: `Authorized user successfully created!`,
				errorMessage: "",
				isLoading: false,
			}
		case ADMIN_CREATE_AUTH_USER_ERROR:
			return {
				...state,
				successMessage: ``,
				errorMessage: action.payload.message,
				isLoading: false,
			}
		case FILL_DEFAULT_EVENT:
			return {
				...state,
				eventName: "Default Event Name",
				eventLocation: "Default Event Location",
				eventDescription: "Default Event Description",
				startTime: "12:30",
				endTime: "01:30",
				housesList: [],
				ticketPrice: 0,
				date: "2022-07-30",
				eventStaff: [],
				soberDrivers: [],
				errorMessage: "",
			}

	}
};

export default reducer;
