import React from "react";
import classNames from "classnames";
import { SectionProps } from "../../utils/SectionProps";
import { Link } from "react-router-dom";
import SectionHeader from "./partials/SectionHeader";
import Input from "../elements/Input";
import Button from "../elements/Button";
import { withRouter } from "react-router-dom";
import { withUser } from "../layout/withUser";

const propTypes = {
	...SectionProps.types,
};

const defaultProps = {
	...SectionProps.defaults,
};

class LoginForm extends React.Component {
	state = {
		username: "",
		password: "",
		errorMessage: "",
		successMessage: "",
	};
	render() {
		const {
			className,
			topOuterDivider,
			bottomOuterDivider,
			topDivider,
			bottomDivider,
			hasBgColor,
			invertColor,
			...props
		} = this.props;

		const outerClasses = classNames(
			"signin section",
			topOuterDivider && "has-top-divider",
			bottomOuterDivider && "has-bottom-divider",
			hasBgColor && "has-bg-color",
			invertColor && "invert-color",
			className
		);

		const innerClasses = classNames(
			"signin-inner section-inner",
			topDivider && "has-top-divider",
			bottomDivider && "has-bottom-divider"
		);

		const sectionHeader = {
			title: "Welcome back to FratPak!",
		};
		const handleChange = (e) => {
			this.setState({ ...this.state, [e.target.name]: e.target.value });
		};
		const onClickLogin = async (e) => {
			e.preventDefault();
			const { username, password } = this.state;
			this.setState({...this.state, errorMessage: ""})
			if (!username || !password) {
				this.setState({
					...this.state,
					successMessage: "",
					errorMessage: "Please input all information.",
				});
			} else {
				const loginUser = this.props.loginuser
				await loginUser({username, password});
				if (!this.props.errorMessage && !this.state.errorMessage) {
					this.props.history.push("/events");
				}
			}
		};

		const onClickForgotPassword = async (e) => {
			e.preventDefault()
			const {username} = this.state;
			if (!username) {
				this.setState({
					...this.state,
					successMessage: "",
					errorMessage: "Please input your email into the form!",
				});
			} else {
				const sendResetEmail = this.props.sendResetEmail
				await sendResetEmail(username);
			}

		}


		const isLoading = this.props.isLoading;

		return (
			<section {...props} className={outerClasses}>
				<div className="container">
					<div className={innerClasses}>
						<SectionHeader
							tag="h1"
							data={sectionHeader}
							className="center-content"
						/>
						<div className="tiles-wrap">
							<div className="tiles-item">
								<div className="tiles-item-inner">
									<form>
										<fieldset>
											<div className="mb-12">
												<Input
													type="email"
													label="Email"
													name="username"
													placeholder="Email"
													labelHidden
													value={this.state.username}
													onChange={handleChange}
													required
													disabled={isLoading}
												/>
											</div>
											<div className="mb-12">
												<Input
													type="password"
													label="Password"
													name="password"
													placeholder="Password"
													labelHidden
													value={this.state.password}
													onChange={handleChange}
													required
													disabled={isLoading}
												/>
											</div>
											<div className="mt-24 mb-32">
												<Button color="primary" wide onClick={onClickLogin} disabled={isLoading}>
													Sign in 
												</Button>
											</div>
											<div className="signin-footer mb-32">
												<Button
													wide
													onClick={onClickForgotPassword}
													className="func-link text-xs"
													disabled={isLoading}
												>
													Forgot password?
												</Button>
											</div>
											<div
												className="mt-24 mb-32"
												style={{ background: "white", borderRadius: 15 }}
											>
												<p
													style={
														this.props.errorMessage.length !== 0
															? {
																	padding: 5,
																	color: "red",
																	fontSize: 15,
																	textAlign: "center",
															  }
															: {}
													}
												>
													{this.props.errorMessage}
												</p>
											</div>
											<div
												className="mt-24 mb-32"
												style={{ background: "white", borderRadius: 15 }}
											>
												<p
													style={
														this.state.errorMessage.length !== 0
															? {
																	padding: 5,
																	color: "red",
																	fontSize: 15,
																	textAlign: "center",
															  }
															: {}
													}
												>
													{this.state.errorMessage}
												</p>
											</div>
											<div
												className="mt-24 mb-32"
												style={{ background: "white", borderRadius: 15 }}
											>
												<p
													style={
														this.props.successMessage.length !== 0
															? {
																	padding: 5,
																	color: "green",
																	fontSize: 15,
																	textAlign: "center",
															  }
															: {}
													}
												>
													{this.props.successMessage}
												</p>
											</div>
											
										</fieldset>
									</form>
									<div className="signin-bottom has-top-divider">
										<div className="pt-32 text-xs center-content text-color-low">
											You must have an account to use this site. Create your account on the app!
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
}

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default withRouter(withUser(LoginForm));
