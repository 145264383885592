import React, { useEffect, useState } from "react";
import Input from "../../components/elements/Input";
import Button from "../../components/elements/Button";
import Switch from "../../components/elements/Switch";
import Select from "react-select";
import { useAppContext } from "../../context/appContext";
import { Redirect, useHistory } from "react-router-dom"

const initState = {firstName: "", lastName: "", email: "", house: "", school: "", birthday: ""}

export default function AdminAddAuthorizedUser () {
    const [values, setValues] = useState(initState)
    const {isLoading, admin_getSchoolsContext, admin_createAuthUser, errorMessage, successMessage, handleChange, user, schools} = useAppContext();

    const handleInputChange = (e) => {
        e.preventDefault()
        setValues({...values, [e.target.name]: e.target.value})
    }

    const handleChangeForSchool = (e) => {
        
        setValues({...values, school: e})
    }

    const handleChangeForHouse = (e) => {
        setValues({...values, house: e})

    }
   

    useEffect(() => {
        (async () => {
            admin_getSchoolsContext();
        })();
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();
        const {firstName, lastName, email, school, house, birthday} = values
        if(!firstName || !lastName || !email  || !school || !house || !birthday) {
            handleChange("errorMessage", "Please input all values!")
            return
        } else {
            const houseObject = schools.filter(school => school.realName === values.school.label)[0]["Houses"][house.label]
			const splitDate = birthday.split("-")
			const modDate = splitDate[1] + "-" + splitDate[2] + "-" + splitDate[0]
            admin_createAuthUser({firstName, lastName, email, school: school.label, greekHouse: houseObject, dateOfBirth: modDate})
            
        }
    }
    return (
        <React.Fragment>
			<div className="container">
				<div className="mb-4 sm:mb-0 mt-6">
					<h3
						className="text-md md:text-xl text-slate-800 font-bold"
						style={{ paddingTop: 50 }}
					>
						Add an Authorized User
					</h3>
					
					</div>
				{isLoading || !user ? (
					<div className="hero-inner section-inner center-content">
						<Button loading={isLoading}>Loading</Button>
					</div>
				) : (
					<div
						className="container has-top-divider"
						style={{ paddingBottom: 0 }}
					>
						<div className="container section-inner">
							<Input
								type="text"
								name="firstName"
								value={values.firstName}
								onChange={handleInputChange}
								label="First Name"
							/>
							<Input
								type="text"
								name="lastName"
								value={values.lastName}
								onChange={handleInputChange}
								label="Last Name"
							/>
							<Input
								type="date"
								name="birthday"
								value={values.birthday}
								onChange={handleInputChange}
								label="Birthday"
							/>
							<Input
								type="email"
								name="email"
								value={values.email}
								onChange={handleInputChange}
								label="Email"
							/>
							<div className="center-content row column">
                                {schools &&
							<>
							<label style={{paddingTop: 15}}>School</label>
							<Select
								options={schools.map((school) => {return {label: school.realName, value: school.realName}})}
								name="school"
								value={values.school}
								onChange={handleChangeForSchool}
							/>
							</>
                                }
                            
						    </div>

                            <div className="center-content row column">
                                {schools && values.school &&
							<>
							<label style={{paddingTop: 15}}>House</label>
							<Select
								options={Object.keys(schools.filter(school => school.realName === values.school.label)[0].Houses).map((house) => {return {label: house, value: house}})}
								name="house"
								value={values.house}
								onChange={handleChangeForHouse}
							/>
							</>
                                }
                            
						    </div>
							
							<div />
							
						</div>
						
						<div className="center-content" style={{ padding: 15 }}>
							<Button className="mt-15" onClick={onSubmit}>
								Submit
							</Button>
						</div>
						{errorMessage.length > 0 ? (
							<div
								className="mt-24"
								style={{ background: "white", borderRadius: 15 }}
							>
								<p
									style={
										errorMessage.length !== 0
											? {
												padding: 5,
												color: "red",
												fontSize: 15,
												textAlign: "center",
											}
											: {}
									}
								>
									{errorMessage}
								</p>
							</div>
						) : (
							<div />
						)}
						{successMessage.length > 0 ? (
							<div
								className="mt-24"
								style={{ background: "white", borderRadius: 15 }}
							>
								<p
									style={
										successMessage.length !== 0
											? {
												padding: 5,
												color: "green",
												fontSize: 15,
												textAlign: "center",
											}
											: {}
									}
								>
									{successMessage}
								</p>
							</div>
						) : (
							<div />
						)}
					</div>
				)}
			</div>
		</React.Fragment>
    )
}