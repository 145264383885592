import React, { useMemo, useEffect, useState } from "react";
import {
    useFilters,
    useGlobalFilter,
    useTable,
    useAsyncDebounce,
} from "react-table";
import Button from "../components/elements/Button";
import { Link } from "react-router-dom";
import { useAppContext } from "../context/appContext";

import * as Papa from "papaparse"
import FormLabel from "../components/elements/FormLabel";
import Switch from "../components/elements/Switch";

const initialValues = {
    headerCheckbox: false,
};

function HouseMemberDataUpload() {
    const [values, setValues] = useState(initialValues);
    const [file, setFile] = useState("")
    const [filename, setFilename] = useState("Choose File")
    const [house, setHouse] = useState("")
    const [school, setSchool] = useState("")
    const [data, setData] = useState([])
    const { obj, isLoading, errorMessage, getAllEvents, handleChange, uploadMembers, successMessage } = useAppContext();

    useEffect(() => {
        (async () => {
        })();
    }, []);

    const parseFile = (file) => {
        handleChange("isLoading", true)
        handleChange("errorMessage", "")
        try {
            if (values.headerCheckbox) {
                Papa.parse(file, {
                    header: true, complete: function (results, file) {
                        var arr = []
                        for (var i = 0; i < results.data.length; i++) {
                            arr.push({
                                lastName: results.data[i]["Last Name"],
                                firstName: results.data[i]["First Name"],
                                dateOfBirth: results.data[i]["Date of Birth"],
                                emailAddress: results.data[i]["Email Address"]
                            })
                        }
                        setData(arr)
                    }
                })
            } else {
                Papa.parse(file, {
                    header: false, complete: function (results, file) {
                        var arr = []
                        for (var i = 0; i < results.data.length; i++) {
                            if (results.data[i][3]) {
                                arr.push({
                                    lastName: results.data[i][0],
                                    firstName: results.data[i][1],
                                    dateOfBirth: results.data[i][2],
                                    emailAddress: results.data[i][3]
                                })
                            }
                        }
                        setData(arr)
                    }
                })
            }
        }
        catch (e) {
            handleChange("errorMessage", e.message)
        }
        handleChange("isLoading", false)
    }

    const parseFilename = (name) => {
        handleChange("isLoading", true)
        const res = name.match(/[A-z^ ]*/g)
        if (res.length !== 6 || res[4] !== "csv") {
            handleChange("isLoading", false)
            handleChange("errorMessage", "Incorrect CSV filename format")
        } else {
            setHouse(res[2].slice(1, -1))
            setSchool(res[0].slice(1, -1))
        }
        handleChange("isLoading", false)
    }

    const onChange = (e) => {
        if (e.target.files.length > 0) {
            setFilename(e.target.files[0].name);
            parseFile(e.target.files[0]);
            parseFilename(e.target.files[0].name)
        }
    };

    const onSubmit = (e) => {
        e.stopPropagation();
        if (!errorMessage) {
            if (data && house && school) { uploadMembers({ members: data, house, school }) }
            else {
                handleChange("errorMessage", errorMessage + " Submission failed due to missing information.")
            }

        } else {
            handleChange("errorMessage", errorMessage + " Cannot upload members without dealing with errors.")
        }

    }

    const onClickHeader = (e) => {
        e.stopPropagation()
        setValues({ ...values, headerCheckbox: !values.headerCheckbox })
    }




    return (
        <React.Fragment>
            <div className="container">
                <div className=" sm:mb-0 mt-6 row">
                    <h3
                        className="text-md md:text-xl text-slate-800 font-bold column"
                        style={{ paddingTop: 50 }}
                    >
                        Upload House Members ✨
                    </h3>

                </div>

                <div
                    className="container has-top-divider center-content"
                    style={{ paddingBottom: 10 }}
                >
                    <h4 style={{ paddingTop: 100 }}> Filename format: [School Name]-[House Name].csv</h4>
                    <h4 style={{ paddingTop: 10 }}> File format: Last Name,First Name,Date of Birth,Email Address</h4>
                    <p className="text-color-error" style={{ textColor: "red" }}>
                        {errorMessage}

                    </p>
                    <p className="text-color-success">
                        {successMessage}
                    </p>
                    <Switch checked={values.headerCheckbox} onChange={onClickHeader} leftLabel="Check this box if your CSV contains a header." />
                    <div className="container section-inner">
                        <input type="file" className="" id="customFile" accept=".csv" onChange={onChange} />
                    </div>
                    <div className="hero-inner section-inner center-content">

                        <div />
                        {house !== "" && ("House: " + house)}
                        <div />
                        {school !== "" && ("School: " + school)}
                        {data.length > 0 && <MemberTable data={data} />}
                        <div />
                        {isLoading &&
                            <Button loading={isLoading}>Loading</Button>
                        }
                        {!isLoading &&
                            <Button onClick={onSubmit}>Submit</Button>
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default HouseMemberDataUpload;


function MemberTable({ data }) {
    const columns = useMemo(() => [
        {
            Header: "Last Name",
            accessor: "",
        },
        {
            Header: "First Name",
            accessor: "",
        },
        {
            Header: "Date of Birth",
            accessor: "",
        },
        {
            Header: "Email Address",
            accessor: "",
        },
    ]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state,
        visibleColumns,
        prepareRow,
        setGlobalFilter,
        preGlobalFilteredRows,
    } = useTable(
        {
            columns,
            data,
            autoResetHiddenColumns: false,
        },
        useFilters,
        useGlobalFilter
    );

    return (
        <>
            <table
                className="table-auto w-full divide-y divide-slate-200"
                {...getTableProps()}
            >
                <thead>

                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr key={i}>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div className="font-bold text-slate-800">
                                        <div style={{ textAlign: "center" }}>
                                            {data[i]["lastName"]}
                                        </div>
                                    </div>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div style={{ textAlign: "center" }}>
                                        {data[i]["firstName"]}
                                    </div>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div style={{ textAlign: "center" }}>
                                        {data[i]["dateOfBirth"]}
                                    </div>
                                </td>
                                <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                                    <div style={{ textAlign: "center" }}>
                                        {data[i]["emailAddress"]}
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </>
    );
}
